import _ from 'lodash'

export abstract class AbstractSearchService<TCT = any, S = any> {
  data = {} as Partial<S>

  $formUtilRef: any = null

  constructor(protected context: TCT) {
    this.data = this.getDefaultFormData()
  }

  getDefaultFormData(): Partial<S> {
    return {}
  }

  getSearchParams(options = { noEmptyString: true }) {
    return options.noEmptyString ? this.omitEmptyString(this.data) : this.data
  }

  protected omitEmptyString(data) {
    return _.omitBy(data, v => {
      return _.isString(v) && !_.size(v)
    })
  }

  abstract onSubmit(): void
}
