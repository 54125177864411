<template>
  <el-form
    ref="form"
    :model="edit.data"
    label-width="8em"
    size="small"
    v-loading="edit.loading"
  >
    <div
      class="section"
      v-for="(it, index) in edit.data.resource?.items"
      :key="it.id"
    >
      <div class="hd">
        <ElCheckbox class="checkbox" v-model="it.visible" />
        <div class="title">{{ it.name }}</div>
        <ElFormItem label-width="0" style="margin-bottom: 0">
          <ElInput v-model="it.alias" placeholder="别名" style="width: 160px" />
        </ElFormItem>
        <div class="actions">
          <ElFormItem label="排序" label-width="80px" style="margin-bottom: 0">
            <ElInputNumber
              v-model="it.sort"
              :min="1"
              :max="9999"
              :controls="false"
              style="width: 50px"
            />
          </ElFormItem>
        </div>
      </div>
      <div class="bd" v-if="it.data">
        <template v-if="it.id === 'home'">
          <ElFormItem
            label="首页模版"
            label-width="6em"
            style="margin-bottom: 0"
            :prop="`resource.items.${index}.data.items`"
            :rules="[{ required: it.visible, message: '请选择首页模版' }]"
          >
            <ElRadioGroup v-model="it.data.items">
              <ElRadioButton
                v-for="it in LibType"
                :key="it.value"
                :label="it.value"
              >
                {{ it.label }}
              </ElRadioButton>
            </ElRadioGroup>
          </ElFormItem>
        </template>

        <template v-if="it.id === 'topic'">
          <ElFormItem
            label="书单标签"
            label-width="6em"
            style="margin-bottom: 0"
            :prop="`resource.items.${index}.data.items`"
            :rules="[{ required: it.visible, message: '请选择书单标签' }]"
          >
            <ElCheckboxGroup v-model="it.data.items">
              <ElCheckbox v-for="it in projectList" :key="it.id" :label="it.id">
                {{ it.name }}
              </ElCheckbox>
            </ElCheckboxGroup>
          </ElFormItem>
        </template>

        <template v-if="it.id === 'book'">
          <ElFormItem
            label="图书分类"
            label-width="6em"
            style="margin-bottom: 0"
            :prop="`resource.items.${index}.data.items`"
            :rules="[{ required: it.visible, message: '请选择图书分类' }]"
          >
            <ElCheckboxGroup v-model="it.data.items">
              <ElCheckbox
                v-for="it in BookTypeRoot"
                :key="it.value"
                :label="it.value"
              >
                {{ it.label }}
              </ElCheckbox>
            </ElCheckboxGroup>
          </ElFormItem>
        </template>

        <template v-if="it.id === 'magazine'">
          <ElFormItem
            label="期刊分类"
            label-width="6em"
            style="margin-bottom: 0"
            :prop="`resource.items.${index}.data.items`"
            :rules="[{ required: it.visible, message: '请选择期刊分类' }]"
          >
            <ElCheckboxGroup v-model="it.data.items">
              <ElCheckbox
                v-for="it in MagazineTypeRoot"
                :key="it.value"
                :label="it.value"
              >
                {{ it.label }}
              </ElCheckbox>
            </ElCheckboxGroup>
          </ElFormItem>
        </template>
      </div>
    </div>
  </el-form>
</template>

<script lang="ts" setup>
import { GET } from '@/admin-shared-modules/utils/ajax'
import { BookTypeRoot, LibType, MagazineTypeRoot } from '@/consts'
import { defineExpose, defineProps, ref } from 'vue'

const props = defineProps<{
  edit: any
}>()

const { edit } = props

const projectList = ref<{ id: number; name: string }[]>([])
async function fetchProjectList() {
  const res = await GET('labels', {})
  projectList.value = res.data
}
fetchProjectList()

// Export
function getForm() {
  return this.$refs.form
}
defineExpose({ getForm })
</script>

<style scoped lang="scss">
.section {
  margin-bottom: 8px;
  padding: 16px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  transition: border ease-in-out 0.2s;
  &:hover {
    border-color: #409eff;
  }
  .hd {
    display: flex;
    align-items: center;
    .checkbox {
      margin-right: 1em;
    }
    .title {
      margin-right: 2em;
      // width: 14em;
      color: #333;
      font-weight: bold;
    }
    .actions {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }
  .bd {
    margin-top: 1em;
    margin-left: 85px;
    background-color: #f7f7f7;
    border-radius: 4px;
    padding: 16px 8px;
  }
}
</style>
