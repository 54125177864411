import { render, staticRenderFns } from "./base-form.vue?vue&type=template&id=4115108a&scoped=true"
import script from "./base-form.vue?vue&type=script&setup=true&lang=ts"
export * from "./base-form.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4115108a",
  null
  
)

export default component.exports