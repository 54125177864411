import _ from 'lodash'
import { MessageBox } from 'element-ui'

export interface MessageBoxServiceOptions {
  title?: string
  message: string
  type?: 'success' | 'warning' | 'info' | 'error'
  confirmButtonText?: string
  cancelButtonText?: string
  dangerouslyUseHTMLString?: boolean
}

export class MessageBoxService {
  static alert(options: MessageBoxServiceOptions) {
    MessageBox.alert(options.message, options.title || '提示', options)
  }
  static async confirm(options: MessageBoxServiceOptions) {
    options = _.assign(
      {
        type: 'warning',
        confirmButtonText: null,
        cancelButtonText: null
      },
      options
    )
    await MessageBox.confirm(options.message, options.title || '提示', options)
  }
}
