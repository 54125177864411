<template>
  <el-container class="layout">
    <app-nav />

    <el-container direction="vertical" class="main">
      <app-header />
      <el-main class="body">
        <router-view />
      </el-main>
      <el-footer class="footer">
        Copyright &copy; 2018 - {{ year }} 超星
      </el-footer>
    </el-container>
  </el-container>
</template>

<script lang="ts">
import moment from 'moment'
import AppNav from './app-nav.vue'
import AppHeader from './app-header.vue'

export default {
  components: {
    AppNav,
    AppHeader
  },
  data() {
    return {
      year: moment().year()
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #f0f2f5;
}
.main {
  display: block;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #f0f2f5;
}
.body {
  min-height: calc(100vh - 64px - 60px);
  padding: 0;
  overflow: visible;
}
.footer {
  text-align: center;
  color: #999;
}
</style>
