<template>
  <div class="page-header">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item v-for="(crumb, index) in breadcrumb" :key="index">
        {{ crumb }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script lang="ts">
export default {
  name: 'PageHeader',
  props: {
    breadcrumb: {
      type: Array,
      require: true
    }
  }
}
</script>

<style lang="scss">
.page-header {
  display: flex;
  align-items: center;
  height: 56px;
  background: #fff;
  padding: 0 32px;
  border-bottom: 1px solid #e8e8e8;
  .el-breadcrumb__item:last-child {
    .el-breadcrumb__inner {
      color: #000 !important;
    }
  }
}
</style>
