<template>
  <page-layout v-loading="list.loading">
    <page-header :breadcrumb="['系统', '角色权限']" />

    <page-main>
      <page-action-bar>
        <el-button type="primary" size="small" @click="e => edit.onAdd()">
          添加
        </el-button>
      </page-action-bar>

      <el-table ref="table" :data="list.items">
        <el-table-column label="角色" prop="name" />
        <el-table-column label="创建时间">
          <template slot-scope="{ row }">
            {{ row.created_at }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{ row }">
            <el-switch
              :value="!row.is_disable"
              @change="state => setRoleState(row, state)"
            />
            {{ row.is_disable ? '禁用' : '启用' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="e => edit.onEdit(row)">
              编辑
            </el-button>
            <el-button type="text" @click="e => edit.onRemove(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="i => list.handlePageIndexChange(i)"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import Edit from './edit.vue'
import { store } from './store'
import { GET, PUT } from '@/admin-shared-modules/utils/ajax'

export default {
  components: {
    Edit
  },

  data() {
    return {
      store,
      list: store.list,
      edit: store.edit
    }
  },
  created() {
    this.list.fetch({ reset: true })
  },
  methods: {
    async setRoleState(item, state) {
      this.list.loading = true
      await PUT(`role/${item.id}`, {
        data: {
          is_disable: !state
        }
      })
      item.is_disable = !state
      this.list.loading = false
    }
  }
}
</script>

<style scoped></style>
