import _ from 'lodash'
import { GET } from '@/admin-shared-modules/utils/ajax'
import { Region } from '@/admin-shared-modules/typings'

const AREA_CACHE = <{ string: Region[] }>{}

class Store {
  async fetch(parentId: number = null): Promise<Region[]> {
    const key = parentId || '0'

    if (AREA_CACHE[key]) return AREA_CACHE[key]

    const res = await GET(`region`, {
      data: {
        parent_id: parentId
      }
    })
    AREA_CACHE[key] = res.data.items
    return res.data.items
  }

  async getProvinceById(id: number) {
    const data = await this.fetch()
    return _.find(data, { id })
  }
}

export default new Store()
