<template>
  <el-form class="page-search" :inline="true" size="small">
    <el-form-item label="姓名">
      <el-input v-model="search.data.user_name_like" clearable />
    </el-form-item>
    <el-form-item label="手机号">
      <el-input v-model="search.data.phone_like" clearable />
    </el-form-item>
    <el-form-item label="角色">
      <el-select v-model="search.data.role_id" clearable>
        <el-option
          v-for="role in edit.roles"
          :key="role.id"
          :label="role.name"
          :value="role.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> 查询 </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import { store } from './store'

export default {
  data() {
    return {
      store: store,
      search: store.search,
      edit: store.edit
    }
  },

  methods: {
    onSubmit() {
      store.list.fetch({ reset: true })
    }
  }
}
</script>
