export const RegionData = [
  { id: 11000000, name: '北京' },
  { id: 12000000, name: '天津' },
  { id: 13000000, name: '河北省' },
  { id: 14000000, name: '山西省' },
  { id: 15000000, name: '内蒙古' },
  { id: 21000000, name: '上海' },
  { id: 22000000, name: '浙江省' },
  { id: 23000000, name: '江苏省' },
  { id: 24000000, name: '安徽省' },
  { id: 25000000, name: '福建省' },
  { id: 26000000, name: '山东省' },
  { id: 31000000, name: '广东省' },
  { id: 32000000, name: '广西' },
  { id: 33000000, name: '海南省' },
  { id: 41000000, name: '湖北省' },
  { id: 42000000, name: '湖南省' },
  { id: 43000000, name: '河南省' },
  { id: 44000000, name: '江西省' },
  { id: 51000000, name: '黑龙江省' },
  { id: 52000000, name: '吉林省' },
  { id: 53000000, name: '辽宁省' },
  { id: 61000000, name: '宁夏' },
  { id: 62000000, name: '新疆' },
  { id: 63000000, name: '青海省' },
  { id: 64000000, name: '陕西省' },
  { id: 65000000, name: '甘肃省' },
  { id: 71000000, name: '四川省' },
  { id: 72000000, name: '云南省' },
  { id: 73000000, name: '贵州省' },
  { id: 74000000, name: '重庆市' },
  { id: 75000000, name: '西藏' },
  { id: 81000000, name: '台湾省' }
]
