<template>
  <el-form class="page-search" inline>
    <el-form-item label="名称">
      <el-input v-model="search.data.name_like" size="small" clearable />
    </el-form-item>
    <el-form-item label="微服务入口FID">
      <el-input v-model="search.data.fid" size="small" clearable />
    </el-form-item>

    <el-form-item label="机构类型">
      <el-select v-model="search.data.customer_type" size="small" clearable>
        <el-option
          v-for="item in CustomerTypeOptions"
          :key="item.key"
          :value="item.key"
          :label="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="行业">
      <el-select v-model="search.data.lib_type" size="small" clearable>
        <el-option
          v-for="item in LibTypeOptions"
          :key="item.key"
          :value="item.key"
          :label="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="正式馆">
      <el-select v-model="search.data.open_type" size="small" clearable>
        <el-option :value="0" label="正式" />
        <el-option :value="1" label="试用" />
      </el-select>
    </el-form-item>

    <el-form-item label="区域">
      <province-selector
        v-model="search.data.province_id"
        :name-base-value="false"
        size="small"
        clearable
      />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">
        查询
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import ProvinceSelector from '@/components/region-selector/province-selector.component.vue'
import { CustomerTypeOptions, LibTypeOptions } from '@/consts'
import store from './lib.store'

export default {
  components: { ProvinceSelector },

  data() {
    return {
      search: store.search,
      LibTypeOptions,
      CustomerTypeOptions
    }
  },

  methods: {
    onSubmit() {
      store.list.fetch({ reset: true })
    }
  }
}
</script>
