<template>
  <page-layout>
    <page-header :breadcrumb="['用户', '使用反馈']" />

    <page-main v-loading="list.loading">
      <el-table ref="table" :data="list.items" @expand-change="onExpandChange">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form class="table-detail-expand" label-width="5em">
              <el-form-item label="用户">
                {{ props.row.user_user_name }}
              </el-form-item>
              <el-form-item label="手机号">
                {{ props.row.device_type }}
              </el-form-item>
              <el-form-item label="邮箱">
                {{ props.row.email }}
              </el-form-item>
              <el-form-item label="内容">
                {{ props.row.content }}
              </el-form-item>
              <el-form-item label="设备类型">
                {{ props.row._device.padbookType }}
              </el-form-item>
              <el-form-item label="设备编号">
                {{ props.row._device.padbookCode }}
              </el-form-item>
              <el-form-item label="系统版本">
                {{ props.row._device.padbookVersion }}
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>

        <el-table-column type="index" width="50" :index="indexMethod" />

        <el-table-column label="用户">
          <template slot-scope="scope">
            {{ scope.row.user_user_name }}
          </template>
        </el-table-column>
        <el-table-column label="手机号">
          <template slot-scope="scope">
            {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column label="反馈内容">
          <template slot-scope="scope">
            {{ scope.row.content | limitLength(20) }}
          </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | date('YYYY/MM/DD HH:mm') }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>
  </page-layout>
</template>

<script>
import store from './feedback.store'

export default {
  data() {
    return {
      list: store.list
    }
  },

  mounted() {
    store.fetch()
  },

  methods: {
    async handleCurrentChange(index) {
      this.list.index = index
      await store.fetch()
    },
    indexMethod(index) {
      return index + 1 + (this.list.index - 1) * this.list.size
    },
    onExpandChange(row) {
      store.fetchFeedbackDevice(row)
    }
  }
}
</script>
