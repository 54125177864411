<template>
  <el-cascader
    v-if="!fetching"
    v-model="currentValue"
    :options="items"
    :props="{
      value: 'id',
      label: 'name'
    }"
    @expand-change="handleItemChange"
    :clearable="clearable"
    :size="size"
    placeholder="请选择地区"
  />
</template>

<script lang="ts">
import _ from 'lodash'
import { Region } from '../../admin-shared-modules/typings'
import store from './area-selector.store'

export default {
  props: {
    value: { required: true },
    defaultValue: { type: Array, default: null },
    provinceOnly: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    size: { type: String, default: null }
  },
  data() {
    return {
      items: [] as Region[],
      currentValue: this.value,
      fetching: false
    }
  },
  computed: {},
  watch: {
    async value() {
      // await this.initialData()
      this.currentValue = this.provinceOnly ? [this.value] : this.value
    },
    currentValue() {
      if (this.provinceOnly) this.$emit('input', _.last(this.currentValue))
      else this.$emit('input', this.currentValue)
    }
  },

  mounted() {
    this.initialData()
  },

  methods: {
    async initialData() {
      this.fetching = true
      const data = await store.fetch()
      this.items = _.map<Region, Region>(data, item => {
        return {
          ...item,
          children: this.provinceOnly ? null : []
        }
      })

      if (_.size(this.defaultValue)) {
        await this.initialDefaultData()
      }
      this.fetching = false
    },

    async initialDefaultData() {
      const values: number[] = _.clone(this.defaultValue)
      const [provinceId, cityId, areaId] = values
      const province = _.find<Region>(this.items, { id: provinceId })

      while (values.length > 1 && province) {
        const current = values.shift()
        const items = await store.fetch(current)

        if (values.length === 2) {
          province.children = items
        } else if (values.length === 1) {
          const city = _.find<Region>(province.children, { id: cityId })
          city.children = items
        }
      }
    },

    async handleItemChange(currentIds: number[]) {
      const [provinceId, cityId, areaId] = currentIds
      const isLeaf = currentIds.length === 2

      const data = await store.fetch(_.last(currentIds))
      const items = _.map(data, item => {
        return {
          ...item,
          children: isLeaf ? null : []
        }
      })

      const province = _.find<Region>(this.items, { id: provinceId })
      const city = _.find<Region>(province.children, { id: cityId })
      if (currentIds.length === 1) {
        province.children = items
      } else if (currentIds.length === 2 && city) {
        city.children = items
      }
    }
  }
}
</script>
