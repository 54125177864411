<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.data.name"
    class="edit-dialog"
    width="840px"
    :close-on-click-modal="false"
  >
    <el-tabs v-model="step" style="margin-bottom: 20px; margin-top: -20px">
      <el-tab-pane label="阅读本" />
      <el-tab-pane label="资源" />
    </el-tabs>

    <BaseForm v-if="step === '0'" />
    <ResourceForm :edit="edit" v-if="step === '1'" />

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" :loading="edit.loading" @click="onSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import BaseForm from './shared/base-form.vue'
import ResourceForm from './shared/resource-form-shared.vue'
import { store } from './store'

const { edit } = store

const step = ref('0')

watch(
  () => store.edit.visible,
  () => {
    step.value = '0'
  }
)

function onSubmit() {
  store.edit.onEditSubmit()
}
</script>

<style scoped></style>
