<template>
  <div class="page-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageLayout'
}
</script>

<style scoped>
.page-layout {
  margin-bottom: 40px;
}
</style>
