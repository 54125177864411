import _ from 'lodash'
import {
  EditState,
  ListState,
  SearchState
} from '@/admin-shared-modules/typings'
import { DELETE, GET, POST, PUT } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { MaintainAccount } from '@/typing'

type MaintainAccountFormData = MaintainAccount

class MaintainAccountStore {
  list: ListState<MaintainAccount> = {
    items: [],
    index: 1,
    size: 20,
    total: 0,
    loading: true
  }
  search: SearchState<any> = {
    data: {
      name_like: null,
      phone_like: null
    }
  }

  edit: EditState<MaintainAccountFormData> = {
    visible: false,
    isEdit: false,
    data: {} as MaintainAccountFormData,
    loading: false
  }

  async fetch(reset = false) {
    if (reset) this.list.index = 1
    this.list.loading = true
    this.list.items = []
    try {
      const res = await GET('operation/account', {
        data: {
          pageIndex: this.list.index - 1,
          name_like: this.search.name_like,
          phone_like: this.search.phone_like,
          ...this.search.data
        }
      })
      this.list.items = res.data.items
      this.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.list.loading = false
    }
  }

  onAdd() {
    this.edit.data = {} as MaintainAccountFormData
    this.edit.isEdit = false
    this.edit.visible = true
  }
  async onEdit(item: MaintainAccountFormData) {
    this.edit.data = _.cloneDeep(item)
    this.edit.isEdit = true
    this.edit.visible = true
  }
  async onRemove(item: MaintainAccountFormData) {
    try {
      await DELETE(`operation/account/${item.id}`, {})
      MessageService.open({ message: '删除成功' })
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    }
  }

  async onEditSubmit() {
    const data = this.edit.data
    const apiMethod = this.edit.isEdit ? PUT : POST
    const url = this.edit.isEdit
      ? `operation/account/${data.id}`
      : 'operation/account'

    this.edit.loading = true
    try {
      await apiMethod(url, {
        data
      })
      this.edit.visible = false
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.edit.loading = false
    }
  }
}

export default new MaintainAccountStore()
