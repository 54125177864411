import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { API_HOST } from '@/config'
import router from '@/router'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import _ from 'lodash'

interface ajaxOptions extends AxiosRequestConfig {
  baseURL?: string
  headers?: any
  method?: string
  data?: any
}

export interface ServerResponseData {
  code: number
  data: any
  total?: number
  message: string
}

export interface AJAXErrorResult extends ServerResponseData {
  handled: boolean
}

export const ajax = (url: string, options: ajaxOptions) => {
  const axiosOptions: AxiosRequestConfig = {
    baseURL: options.baseURL || API_HOST,
    method: options.method || 'get',
    timeout: 10 * 1000,
    ...options
  }

  if (options.data && _.includes(['get', 'delete'], options.method))
    axiosOptions.params = options.data
  else axiosOptions.data = options.data

  return new Promise<ServerResponseData>((resolve, reject) => {
    axios(url, axiosOptions)
      .then(async (res: AxiosResponse<ServerResponseData>) => {
        resolve(axiosOptions.responseType === 'blob' ? (res as any) : res.data)
      })
      .catch(async (err: AxiosError) => {
        console.error(err)
        if (!err.response) {
          defaultErrorHandler()
          reject({ ...err, handled: true })
          return
        }

        if (err.response.status == 401) {
          reject({ handled: true })
          router.replace({ name: 'signIn' })
        } else if (err.response.status >= 400 || err.response.status < 500) {
          reject({
            data: err.response.data,
            message: _.get(err.response.data, 'msg', '服务繁忙，请稍后重试'),
            handled: false
          })
        } else {
          defaultErrorHandler()
          reject({ ...err, handled: true })
        }
      })
  })
}

const defaultErrorHandler = () => {
  MessageBoxService.alert({ type: 'error', message: '服务繁忙，请稍后重试' })
}

export const GET = (url: string, options: ajaxOptions) =>
  ajax(url, {
    ...options,
    method: 'get'
  })
export const PUT = (url: string, options: ajaxOptions) =>
  ajax(url, {
    ...options,
    method: 'put'
  })
export const POST = (url: string, options: ajaxOptions) =>
  ajax(url, {
    ...options,
    method: 'post'
  })
export const DELETE = (url: string, options: ajaxOptions) =>
  ajax(url, {
    ...options,
    method: 'delete'
  })
