<template>
  <div class="intro-container">
    <vue-particles
      class="backdrop"
      :particles-number="50"
      :hover-effect="false"
      :click-effect="false"
    />

    <div class="page-sign-in">
      <div class="header">
        <h1 class="title">超星阅读器</h1>
        <p class="desc">图书馆运营系统 LOS</p>
      </div>

      <form class="form" @submit.prevent="submit()">
        <div class="form-field" v-if="ajaxErrorMessage">
          <el-alert
            :title="ajaxErrorMessage"
            type="warning"
            show-icon
            :closable="false"
          />
        </div>

        <div class="form-field">
          <el-input
            ref="inputPhone"
            v-model.trim="form.phone"
            placeholder="手机号"
            :autofocus="true"
          />
        </div>
        <div class="form-field-sms">
          <el-input
            style="width: 210px"
            ref="inputCode"
            type="code"
            v-model="form.code"
            placeholder="验证码"
          />
          <el-button
            :disabled="isDisabled"
            style="margin-left: 10px; width: 130px"
            @click="sendMsg"
          >
            {{ buttonName }}
          </el-button>
        </div>
        <div class="form-field-sms" v-if="isDisabled">
          <span class="el-icon-circle-check-outline" style="color: #00cc00" />
          <span style="font-size: 12px; color: #b0b0b0"
            >验证码已发送至您的手机，5分钟内输入有效</span
          >
        </div>
        <div class="form-field">
          <el-button
            type="primary"
            native-type="submit"
            :loading="loading"
            class="block-btn"
          >
            登 录
          </el-button>
        </div>
      </form>
    </div>

    <footer class="footer">Copyright &copy; 2018 - {{ year }} 超星</footer>
  </div>
</template>

<script lang="ts">
import { AJAXErrorResult, POST } from '@/admin-shared-modules/utils/ajax'
import moment from 'moment'
import { MessageService } from '@/admin-shared-modules/utils/message.service'

export default {
  data() {
    return {
      form: {
        phone: '',
        code: '',
        rememberMe: true
      },
      loading: false,
      ajaxErrorMessage: null,
      isDisabled: false,
      buttonName: '获取验证码',
      time: 60,
      year: moment().year()
    }
  },

  mounted() {
    if (process.env.NODE_ENV === 'development') {
      this.form.phone = ''
      this.form.code = ''
    }
  },

  methods: {
    async sendMsg() {
      if (!this.form.phone) {
        MessageService.open({ type: 'error', message: '请输入手机号' })
        return
      }
      try {
        const res = await POST('admin/sms/send', {
          data: {
            phone: this.form.phone
          }
        })
        this.isDisabled = true
        const interval = window.setInterval(() => {
          this.buttonName = `${this.time}秒后可重发`
          --this.time
          if (this.time < 0) {
            this.buttonName = '重新获取'
            this.time = 60
            this.isDisabled = false
            window.clearInterval(interval)
          }
        }, 1000)
      } catch (e) {
        console.error(e)
        const ex: AJAXErrorResult = e
        if (ex.handled) return
        // this.ajaxErrorMessage = ex.message
        MessageService.open({ type: 'error', message: e.message })
      } finally {
      }
    },
    async submit() {
      if (!this.validate()) return

      this.ajaxErrorMessage = null
      this.loading = true
      try {
        await POST(`admin/sms/login`, {
          data: this.form
        })
        setTimeout(() => {
          this.$router.replace('/u')
        }, 500)
      } catch (e) {
        console.error(e)
        this.loading = false
        const ex: AJAXErrorResult = e
        if (ex.handled) return
        // this.ajaxErrorMessage = ex.message
        MessageService.open({ type: 'error', message: e.message })
      }
    },
    validate() {
      const inputPhone = this.$refs['inputPhone']
      const inputCode = this.$refs['inputCode']
      if (!this.form.phone) {
        MessageService.open({ type: 'error', message: '请输入手机号' })
        inputPhone.focus()
        return
      } else if (!this.form.code) {
        MessageService.open({ type: 'error', message: '请输入手机验证码' })
        inputCode.focus()
        return
      }
      return true
    }
  }
}
</script>

<style scoped lang="scss" src="./sign-in.style.scss"></style>
