<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    class="edit-dialog"
    top="8vh"
    width="780px"
    :close-on-click-modal="false"
  >
    <el-steps
      v-if="!edit.isEdit"
      :active="step"
      :align-center="true"
      center
      finish-status="success"
      style="margin-bottom: 30px; margin-top: -20px"
    >
      <el-step title="机构信息" />
      <el-step title="阅读本设置" />
      <el-step title="资源配置" />
      <el-step title="账号开通" />
    </el-steps>

    <el-tabs
      v-if="edit.isEdit"
      v-model="stepForEdit"
      style="margin-bottom: 20px; margin-top: -20px"
    >
      <el-tab-pane label="机构信息" />
      <el-tab-pane label="阅读本设置" />
      <el-tab-pane label="资源配置" />
      <el-tab-pane label="账号开通" />
      <el-tab-pane label="统计倍数" />
      <el-tab-pane label="应用管理" />
    </el-tabs>

    <div
      v-if="edit.isEdit"
      style="position: relative; top: -70px; height: 0; text-align: right"
    >
      <router-link
        :to="{ name: 'readerDevice', query: { lib: edit.data.id } }"
        target="_blank"
        class="el-button el-button--mini el-button--primary"
      >
        设备管理
      </router-link>
    </div>

    <!--step0 base-->
    <base-form
      v-if="edit.visible"
      v-show="step === 0"
      ref="baseFormRef"
      v-loading="edit.loading"
    />

    <!-- step1 device -->
    <device-form
      v-if="edit.visible"
      v-show="step === 1"
      ref="deviceFormRef"
      v-loading="edit.loading"
    />

    <!-- step2 res -->
    <ResourceForm
      v-if="edit.visible"
      v-show="step === 2"
      ref="resourceFromRef"
      :edit="edit"
      v-loading="edit.loading"
    />

    <!-- step3 account -->
    <lib-account v-if="edit.visible && step === 3" :library-id="edit.data.id" />

    <!-- step4 report -->
    <report-grow-form
      v-if="edit.visible && step === 4"
      v-loading="edit.loading"
    />

    <!-- step5 app -->
    <apps v-if="edit.visible && step === 5" v-loading="edit.loading" />

    <div slot="footer" v-if="!edit.isEdit">
      <el-button v-if="step === 0" @click="edit.visible = false">
        取消
      </el-button>
      <el-button v-else @click="stepBack"> 上一步 </el-button>

      <el-button
        v-if="step >= 0 && step < 3"
        type="primary"
        :loading="edit.loading || edit.saving"
        @click="saveAndNext"
      >
        保存, 下一步
      </el-button>
      <el-button
        v-if="step === 3"
        type="primary"
        :loading="edit.loading || edit.saving"
        @click="saveAndNext"
      >
        完成
      </el-button>
    </div>
    <div slot="footer" v-if="edit.isEdit">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :loading="edit.saving"
        :disabled="edit.loading || edit.saving"
        @click="onSubmit"
      >
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { computed, ref, watch } from 'vue'
import ResourceForm from '../lib-template/shared/resource-form-shared.vue'
import LibAccount from './form/account/account.component.vue'
import Apps from './form/apps.vue'
import BaseForm from './form/base-form.vue'
import DeviceForm from './form/device-form.vue'
import ReportGrowForm from './form/report-grow-form.vue'
import store from './lib.store'

const edit = store.edit
const step = ref(0)

const stepForEdit = computed({
  get: () => `${step.value}`,
  set: val => (step.value = +val)
})

watch(
  () => edit.visible,
  () => (step.value = 0)
)

const baseFormRef = ref<any>(null)
const deviceFormRef = ref<any>(null)
const resourceFromRef = ref<any>(null)

function stepBack() {
  step.value -= 1
}

async function saveAndNext() {
  if (step.value === 0) {
    await baseFormRef.value.getForm().validate()
    await store.edit.onEditSubmit()

    // #TPAD 1000634
    // if ([7, 8, 9].includes(edit.data.lib_type)) {
    //   edit.data.device_type = ['READER']
    //   edit.data.login_type = [2]
    //   edit.data.applications = [2, 4]
    //   edit.data.qk_type = 3
    // }
  }
  if (step.value === 1) {
    await deviceFormRef.value.getForm().validate()
    await store.edit.onEditSubmit()
  }
  if (step.value === 2) {
    await store.edit.onEditSubmit()
  }
  if (step.value === 3) {
    edit.visible = false
    store.list.fetch()
    return
  }
  step.value += 1
}

async function onSubmit() {
  try {
    await baseFormRef.value.getForm().validate()
  } catch (e) {
    console.error(e)
    if (step.value !== 0) {
      step.value = 0
    }
    MessageBoxService.alert({
      type: 'warning',
      title: '提示',
      message: '请检查[机构信息]是否填写完整'
    })
    return
  }
  try {
    await deviceFormRef.value.getForm().validate()
  } catch (e) {
    console.error(e)
    if (step.value !== 1) {
      step.value = 1
    }
    MessageBoxService.alert({
      type: 'warning',
      title: '提示',
      message: '请检查[阅读本设置]是否填写完整'
    })
    return
  }
  try {
    await resourceFromRef.value.getForm().validate()
  } catch (e) {
    console.error(e)
    if (step.value !== 2) {
      step.value = 2
    }
    MessageBoxService.alert({
      type: 'warning',
      title: '提示',
      message: '请检查[资源配置]是否填写完整'
    })
    return
  }

  try {
    await edit.onEditSubmit()
    edit.visible = false
  } catch (e) {
    defaultErrorHandler(e)
  }
}
</script>

<style scoped lang="scss" src="./lib.style.scss" />
