import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { POST, PUT } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { cloneDeep } from 'lodash'
import { Ref, nextTick, reactive, ref, unref } from 'vue'

export function useEdit<FormData = any, P = any>(props: {
  fetch?: {
    url?: string | Ref<string>
    prepareFormData?: (data: FormData | null, params: P) => FormData
  }
  submit?: {
    url: string | Ref<string>
    data?: FormData | Ref<FormData>
    post?: () => void
  }
}) {
  const visible = ref(false)
  const isEdit = ref(false)

  const loading = ref(false)
  const saving = ref(false)

  const params = ref<P>()
  const formData = ref({}) as Ref<FormData>

  // Actions driven
  async function onAdd(_params: P) {
    visible.value = true
    params.value = _params
    isEdit.value = false
    await initFormData()
  }

  async function onEdit(_params: P) {
    visible.value = true
    params.value = cloneDeep(_params)
    isEdit.value = true
    await initFormData()
  }

  async function onSubmit() {
    if (!props.submit || !props.submit.url) {
      return
    }

    await nextTick()

    const { url, data, post } = props.submit
    const API = isEdit.value ? PUT : POST

    console.log('onSubmit', unref(url), unref(data))

    try {
      saving.value = true

      await API(unref(url), {
        baseURL: '/',
        data: data ? unref(data) : formData.value
      })

      MessageService.open({
        type: 'success',
        message: '保存成功'
      })
      visible.value = false
      post?.()
    } catch (error) {
      defaultErrorHandler(error)
    } finally {
      saving.value = false
    }
  }

  async function initFormData() {
    if (!props.fetch) {
      formData.value = params.value as unknown as FormData
      return
    }

    await nextTick()

    const { url, prepareFormData } = props.fetch || {}

    if (prepareFormData) {
      // eslint-disable-next-line
      let remoteData: FormData | null = null

      if (url) {
        // remoteData = await fetch()
      }

      formData.value = prepareFormData(remoteData, params.value)
    } else {
      formData.value = params.value as unknown as FormData
    }
  }

  const editState = reactive({
    visible,
    isEdit,
    loading,
    saving,
    params,
    formData
  })

  return {
    visible,
    isEdit,
    loading,
    saving,
    params,
    formData,
    editState,

    onAdd,
    onEdit,
    onSubmit
  }
}
