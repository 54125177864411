import { render, staticRenderFns } from "./maintain-account-edit.component.vue?vue&type=template&id=bb273ff0"
import script from "./maintain-account-edit.component.vue?vue&type=script&lang=ts"
export * from "./maintain-account-edit.component.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports