var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.currentValue),function(item,index){return _c('el-form-item',{key:index,staticStyle:{"margin-bottom":"22px"},attrs:{"label":index === 0 ? '微服务入口FID' : '',"prop":`lib_ref.${index}`,"rules":[
      {
        type: 'object',
        fields: {
          schoolid: { validator: _vm.OrgInfoValidator }
        }
      }
    ]}},[_c('div',{staticClass:"group"},[_c('cx-org-selector',{attrs:{"default-item":item.schoolid ? item : null,"clearable":""},on:{"change":org => _vm.onChange(org, index)},model:{value:(item.schoolid),callback:function ($$v) {_vm.$set(item, "schoolid", $$v)},expression:"item.schoolid"}}),(index)?_c('div',{staticClass:"action"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.onRemove}},[_c('i',{staticClass:"el-icon-delete"}),_vm._v(" 删除 ")])],1):_vm._e()],1)])}),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.onAdd}},[_vm._v(" 新增FID ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }