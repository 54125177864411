<template>
  <page-layout v-loading="list.loading">
    <page-header :breadcrumb="['系统', '账号管理']" />

    <page-main>
      <search />

      <page-action-bar>
        <el-button type="primary" size="small" @click="e => edit.onAdd()">
          添加
        </el-button>
      </page-action-bar>

      <el-table ref="table" :data="list.items">
        <el-table-column label="姓名" prop="user_name" />
        <el-table-column label="手机号" prop="phone" />
        <el-table-column label="角色">
          <template slot-scope="{ row }">
            {{ row.roles | join('name') }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="{ row }">
            {{ row.created_at }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="e => edit.onEdit(row)">
              编辑
            </el-button>
            <el-button type="text" @click="e => edit.onRemove(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="i => list.handlePageIndexChange(i)"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import Search from './seach.vue'
import Edit from './edit.vue'
import { store } from './store'
import { GET, PUT } from '@/admin-shared-modules/utils/ajax'

export default {
  components: {
    Search,
    Edit
  },

  data() {
    return {
      store,
      list: store.list,
      edit: store.edit
    }
  },
  async created() {
    this.list.fetch({ reset: true })

    this.list.loading = true
    const { data } = await GET('role', {
      data: { pageSize: 999 }
    })
    this.edit.roles = data.items
    this.list.loading = false
  },
  methods: {}
}
</script>

<style scoped></style>
