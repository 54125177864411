import { AbstractEditService } from '@/core/services/edit.service'
import { AbstractListService } from '@/core/services/list.service'
import { ListFetchOptions } from '@/core/typing'

class List extends AbstractListService<Store> {
  size = 50
  getFetchURL(): string {
    return 'link/list'
  }
}

class Edit extends AbstractEditService<Store> {
  getFetchURL() {
    return `link/${this.params.id}`
  }
  getDefaultFormData() {
    return { icon_url: '', scale: 100, is_bottom: 1 }
  }
  getSubmitURL(): string {
    return this.isEdit ? `link/modify/${this.params.id}` : 'link/add'
  }
  getRemoveURL(): string {
    return `link/${this.params.id}`
  }
  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch(option)
  }
}

class Store {
  list: List
  edit: Edit

  constructor() {
    this.list = new List(this)
    this.edit = new Edit(this)
  }
}

export const store = new Store()
