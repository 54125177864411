<template>
  <el-dialog
    :title="edit.isEdit ? '编辑账号' : '新增账号'"
    :visible.sync="edit.visible"
    @open="onDialogOpen"
    append-to-body
  >
    <el-form
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="150px"
      @submit="onSubmit"
    >
      <el-form-item label="用户姓名" prop="name">
        <el-input v-model.trim="edit.data.name" />
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input v-model.trim="edit.data.phone" />
      </el-form-item>
      <el-form-item label="初始密码" prop="password" v-if="!edit.isEdit">
        <el-input v-model.trim="edit.data.password" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import store from './account.store'

export default {
  data() {
    return {
      edit: store.edit,
      rules: {
        name: [
          { required: true, message: '请填写' },
          { min: 2, message: '长度需大于 2' }
        ],
        phone: [{ required: true, message: '请填写' }],
        password: [
          { required: true, message: '请填写' },
          { min: 6, message: '长度需大于 6' }
        ]
      }
    }
  },

  methods: {
    onDialogOpen() {
      this.$nextTick(() => {
        this.$refs['form'] && this.$refs['form'].clearValidate()
      })
    },

    onSubmit() {
      this.$refs['form'].validate(v => {
        if (!v) return
        store.onEditSubmit()
      })
    }
  }
}
</script>
