import { ListFetchOptions } from '@/core/typing'
import { GET } from '@/admin-shared-modules/utils/ajax'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'

export abstract class AbstractListService<TCT = any, T = any> {
  items = [] as T[]
  index = 1
  size = 30
  total = 0
  loading = false

  constructor(protected context: TCT) {}

  async fetch(options: ListFetchOptions = {}) {
    this.loading = true

    if (options.reset) {
      this.index = 1
    }
    if (options.fixIndex && this.items.length === 1 && this.index > 1) {
      this.index = this.index - 1
    }
    this.items = []

    const url = this.getFetchURL()
    try {
      const res = await GET(url, {
        data: {
          pageIndex: this.index - 1,
          pageSize: this.size,
          ...this.getSearchParams()
        }
      })
      this.items = this.parseFetchedItems(res.data)
      this.total = res.data.totalCount || this.items.length
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.loading = false
    }
  }

  async handlePageIndexChange(current) {
    scrollTo(0, 0)
    this.index = current
    await this.fetch()
  }

  indexMethod(current: number) {
    return current + 1 + (this.index - 1) * this.size
  }

  parseFetchedItems(data): T[] {
    return data.items
  }

  getSearchParams(): any {
    return {}
  }

  abstract getFetchURL(): string
}
