<template>
  <div class="app-nav" :class="{ collapsed: collapseState }">
    <div class="logo">
      <img src="~@/admin-shared-modules/theme/image/logo.png" alt="" />
      <h1 v-show="!collapseState">阅读本总后台管理</h1>
    </div>
    <el-menu
      router
      :default-active="$route.name"
      unique-opened
      :collapse="collapseState"
      :collapse-transition="false"
      background-color="#001529"
      text-color="#cccccc"
      active-text-color="#ffffff"
    >
      <el-submenu v-for="item in menuItems" :key="item.key" :index="item.key">
        <template slot="title">
          <i :class="item.icon" />
          <span>{{ item.title }}</span>
          <!-- <span class="dot" v-if="item.key === 'g-order' && ASFNotifyCount"
            >NEW</span
          > -->
        </template>
        <template v-for="child in item.children">
          <el-menu-item
            :key="child.routerName"
            :route="{ name: child.routerName }"
            :index="child.routerName"
          >
            {{ child.title }}
            <span
              class="dot"
              v-if="child.routerName === 'afterSaleFix' && ASFNotifyCount"
              >{{ ASFNotifyCount }}</span
            >
          </el-menu-item>
        </template>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script lang="ts">
import { getMenuItems } from '@/router/config'
import _ from 'lodash'
import AppStore from '../../store'

export default {
  data() {
    return {
      menuItems: getMenuItems(true)
    }
  },
  computed: {
    collapseState: {
      get() {
        return !AppStore.state.ui.openNav
      },
      set(val) {
        AppStore.state.ui.openNav = !val
      }
    },
    ASFNotifyCount() {
      return _.get(AppStore.state.ui, 'notification.repair_apply', 0)
    }
  }
  // async mounted() {
  //   const res = await GET('navigate/notify', {})
  //   AppStore.state.ui.notification = res.data
  // }
}
</script>

<style lang="scss" scoped>
@import '~@/admin-shared-modules/theme/var';

.app-nav {
  flex: 0 0 240px;
  height: 100vh;
  overflow-y: auto;
  background-color: #001529;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  z-index: 5;
  &.collapsed {
    flex: 0 0 64px;
  }
}
.logo {
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 64px;
  line-height: 64px;
  background: #002140;
  img {
    margin-right: 12px;
    width: 32px;
    height: 32px;
  }
  h1 {
    margin: 0;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
  }
}

.app-nav {
  ::v-deep .el-menu {
    border-right: 0;
    .el-menu--inline > .el-menu-item {
      background-color: #000 !important;
    }
    .el-menu-item.is-active {
      background-color: #409eff !important;
    }
    .el-submenu.is-opened .el-submenu__title,
    .el-submenu.is-active .el-submenu__title {
      color: #fff !important;
      i {
        color: #fff !important;
      }
    }
  }
  .dot {
    margin-left: 0.4em;
    padding: 0 4px;
    color: #fff;
    background: $--color-danger;
    border-radius: 20px;
    font-size: 13px;
  }
}
</style>
