<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    width="500px"
  >
    <el-form
      v-if="edit.visible"
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="姓名" prop="user_name">
        <el-input v-model="edit.data.user_name" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="edit.data.phone" />
      </el-form-item>
      <el-form-item label="角色">
        <el-checkbox-group v-model="edit.data.role_ids">
          <el-checkbox
            v-for="role in edit.roles"
            :key="role.id"
            :label="role.id"
          >
            {{ role.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="edit.loading || edit.saving"
        @click="onSubmit"
      >
        保存
      </el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { store } from './store'
import { getMenuItems } from '@/router/config'
import { GET } from '@/admin-shared-modules/utils/ajax'

export default {
  components: {},

  data() {
    return {
      store,
      edit: store.edit,
      rules: {
        user_name: { required: true, message: '请填写' },
        phone: [
          { required: true, message: '请填写' },
          { len: 11, message: '手机号长度11位' }
        ]
      }
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      store.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';
</style>
