<template>
  <page-layout>
    <page-header :breadcrumb="['数据统计', '图书更新统计']" />

    <page-main v-loading="listState.loading">
      <page-action-bar>
        <el-button type="primary" size="small" @click="onAddClick"
          >新增</el-button
        >
      </page-action-bar>

      <el-form inline size="small">
        <el-form-item label="年份">
          <el-date-picker
            type="year"
            v-model="query.year"
            placeholder="请选择"
            :clearable="false"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <el-table :data="items">
        <el-table-column prop="month" label="月份"></el-table-column>
        <el-table-column prop="count" label="更新图书数"> </el-table-column>
        <el-table-column label="更新目录">
          <template slot-scope="{ row, $index }">
            <a
              v-if="row.list_file"
              :href="row.list_file"
              target="_blank"
              style="margin-right: 1em"
              >{{ row.list_file_name }}</a
            >
          </template>
        </el-table-column>
        <el-table-column width="130">
          <template slot-scope="{ row, $index }">
            <a
              @click="() => onEdit(row)"
              style="margin-right: 1em; cursor: pointer"
              >编辑</a
            >
            <el-popconfirm title="确定删除" @confirm="() => onRemove(row)">
              <a slot="reference" class="text-danger" style="cursor: pointer"
                >删除</a
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </page-main>

    <el-dialog
      :visible.sync="editState.visible"
      :title="editState.isEdit ? '编辑' : '新增'"
      :close-on-click-modal="false"
      class="edit-dialog"
    >
      <el-form
        ref="editForm"
        :model="formData"
        size="small"
        label-width="120px"
        :rules="rules"
        @submit.prevent="handleFormSubmit"
      >
        <el-form-item label="年份">
          <el-input
            v-model="formData.year"
            disabled
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="月份" prop="month">
          <el-input-number
            v-model="formData.month"
            :min="1"
            :max="12"
            size="small"
            controls-position="right"
            style="width: 100px"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="更新图书数" prop="count">
          <el-input-number
            v-model="formData.count"
            :min="0"
            size="small"
            controls-position="right"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="更新目录" prop="list_file">
          <span v-if="formData.list_file">
            <a
              :href="formData.list_file"
              target="_blank"
              class="el-button el-button--primary el-button--small"
              >查看文件: {{ formData.list_file_name }}</a
            >
          </span>
          <el-upload
            action="/api/uploads"
            :show-file-list="false"
            :on-success="handleFileUploadSuccess"
            :on-error="handleFileUploadError"
            :before-upload="beforeUpload"
            :disabled="uploading"
          >
            <el-button
              v-if="!formData.list_file"
              size="small"
              type="primary"
              :loading="uploading"
            >
              上传文件
            </el-button>
            <a v-else>重新上传</a>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="editState.visible = false"> 取消 </el-button>
        <el-button
          type="primary"
          :loading="editState.loading"
          @click="handleFormSubmit"
        >
          保存
        </el-button>
      </div>
    </el-dialog>
  </page-layout>
</template>

<script setup lang="ts">
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { useEdit, useList, useRemove } from '@/hooks'
import moment from 'moment'
import { computed, ref } from 'vue'

const query = ref({ year: moment() })

const { listState, items, refetch } = useList({
  url: computed(() => `/api/monthly_records/list`),
  query: computed(() => ({
    year: moment(query.value.year).format('YYYY')
  }))
})

const { isEdit, formData, editState, onAdd, onEdit, onSubmit } = useEdit({
  submit: {
    url: computed(() =>
      isEdit.value
        ? `/api/monthly_records/modify/${formData.value.id}`
        : `/api/monthly_records/add`
    ),
    post: refetch
  }
})

const { onRemove, removeState } = useRemove({
  url: computed(() => `/api/monthly_records/${removeState.params.id}`),
  post: refetch
})

const editForm = ref(null)
const uploading = ref(false)

function onAddClick() {
  onAdd({
    year: moment(query.value.year).format('YYYY'),
    month: null,
    count: null,
    list_file: null,
    list_file_name: null
  })
}

// Define validation rules
const rules = {
  month: [{ required: true, message: '请选择月份' }],
  count: [{ required: true, message: '请填写更新图书数' }],
  list_file: [{ required: true, message: '请上传文件' }]
}

function beforeUpload(file) {
  uploading.value = true
  formData.value.list_file_name = file.name
  return true
}

function handleFileUploadSuccess(response) {
  formData.value.list_file = response.data.url
  uploading.value = false
  MessageService.open({
    type: 'success',
    message: '文件上传成功'
  })
  editForm.value.clearValidate()
}

function handleFileUploadError() {
  uploading.value = false
  MessageService.open({
    type: 'error',
    message: '文件上传失败'
  })
}

function handleFormSubmit() {
  editForm.value.validate(valid => {
    if (valid) {
      onSubmit()
    } else {
      MessageService.open({
        type: 'error',
        message: '请填写完整的表单'
      })
    }
  })
}
</script>
