<template>
  <div class="result-page">
    <i class="el-icon-warning" />
    无权限访问
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.result-page {
  padding: 24px;
  text-align: center;
  font-size: 20px;
  color: #ff3300;
}
</style>
