<template>
  <div>
    <div style="display: flex">
      <el-form label-width="auto">
        <el-form-item label="首页配置">
          <el-switch v-model="edit.data.lms_homepage_enable" />
        </el-form-item>
      </el-form>
      <el-form label-width="auto" style="margin-left: 200px">
        <el-form-item label="开关机引导配置">
          <el-switch v-model="edit.data.device_guide_enable" />
        </el-form-item>
      </el-form>
    </div>

    <div style="border-top: 1px solid #eee"></div>

    <el-form label-width="auto" style="margin-top: 20px">
      <el-form-item label="链接应用开关">
        <el-switch v-model="edit.data.is_link_app" />
      </el-form-item>
    </el-form>

    <page-action-bar>
      <el-button type="primary" size="small" @click="onAddClick">
        添加应用
      </el-button>
    </page-action-bar>

    <template v-if="list">
      <el-table :data="list.items" size="small">
        <el-table-column label="图标">
          <template slot-scope="{ row }">
            <img :src="row.icon_url" style="width: 60px; height: 60px" />
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="link_name"></el-table-column>
        <el-table-column label="URL">
          <template slot-scope="{ row }">
            <a :href="row.link" target="_blank">{{ row.link }}</a>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="notes"></el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="e => listEdit.onRemove(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- <el-pagination
        @current-change="i => list.handlePageIndexChange(i)"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      /> -->
    </template>

    <el-dialog
      v-if="selector.visible"
      :visible.sync="selector.visible"
      :title="'选择应用'"
      append-to-body
      width="700px"
    >
      <el-table :data="selector.list.items" size="small">
        <el-table-column label="图标">
          <template slot-scope="{ row }">
            <img :src="row.icon_url" style="width: 60px; height: 60px" />
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="link_name"></el-table-column>
        <el-table-column label="URL">
          <template slot-scope="{ row }">
            <a :href="row.link" target="_blank">{{ row.link }}</a>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="notes"></el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="() => onAdd(row)"> 选择 </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="i => selector.list.handlePageIndexChange(i)"
        :current-page="selector.list.index"
        :total="selector.list.total"
        :page-size="selector.list.size"
        layout="total, prev, pager, next"
        background
      />
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { POST } from '@/admin-shared-modules/utils/ajax'
import { AbstractEditService } from '@/core/services/edit.service'
import { AbstractListService } from '@/core/services/list.service'
import store from '../lib.store'

export default {
  data() {
    return {
      edit: store.edit,

      list: null,
      listEdit: null,

      selector: {
        visible: false,
        list: null
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const id = this.edit.data.id

      class List extends AbstractListService<any> {
        size = 50
        getFetchURL(): string {
          return `${id}/link/list`
        }
      }

      class Edit extends AbstractEditService<any> {
        getFetchURL() {
          return ``
        }
        getSubmitURL(): string {
          return ``
        }
        getRemoveURL(): string {
          return `${id}/link/list`
        }
        getRemoveParams(item) {
          return { ids: item.id }
        }
        requestListReload() {
          this.context.list.fetch({ reset: true })
        }
      }

      this.list = new List(null)
      this.listEdit = new Edit(this)
      this.list.fetch({ reset: true })

      class SelectList extends AbstractListService<any> {
        size = 20
        getFetchURL(): string {
          return 'link/list'
        }
      }
      this.selector.list = new SelectList(null)
    },
    onAddClick() {
      this.selector.visible = true
      this.selector.list.fetch({ reset: true })
    },
    async onAdd(item) {
      await POST(`${this.edit.data.id}/link/list`, {
        data: {
          ids: `${item.id}`
        }
      })
      this.selector.visible = false
      this.$message.success('添加成功')
      this.list.fetch()
    }
  }
}
</script>

<style scoped></style>
