import { render, staticRenderFns } from "./cx-org-selector.vue?vue&type=template&id=442281b6&scoped=true"
import script from "./cx-org-selector.vue?vue&type=script&lang=ts"
export * from "./cx-org-selector.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "442281b6",
  null
  
)

export default component.exports