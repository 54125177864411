<template>
  <div class="page-main" v-loading="loading">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageMain',
  props: {
    loading: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.page-main {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 24px 24px 20px;
  padding: 24px;
  min-height: 100px;
  background: #fff;
  border-radius: 2px;
}
</style>
