import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { DELETE } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { Ref, reactive, ref, unref, watch } from 'vue'

export function useRemove<P = any>(props: {
  url: string | Ref<string>
  data?: any | Ref<any>
  post?: () => void
}) {
  const loading = ref(false)
  const params = ref<P>()

  async function onRemove(_params: P) {
    params.value = _params
  }

  async function submit() {
    try {
      await DELETE(unref(props.url), {
        baseURL: '/',
        data: props.data ? unref(props.data) : null
      })

      MessageService.open({
        type: 'success',
        message: '删除成功'
      })
      props.post?.()
    } catch (error) {
      defaultErrorHandler(error)
    } finally {
      loading.value = false
    }
  }
  watch(params, async params => {
    if (params) {
      await submit()
    }
  })

  const removeState = reactive({
    loading,
    params
  })

  return {
    loading,
    params,
    removeState,
    onRemove
  }
}
