<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    width="500px"
  >
    <el-form
      v-if="edit.visible"
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="图标" prop="icon_url">
        <el-upload
          action="/api/uploads"
          drag
          :show-file-list="false"
          :on-success="handleUploadChange"
          :before-upload="beforeUpload"
        >
          <img
            v-if="edit.data.icon_url"
            :src="edit.data.icon_url"
            class="avatar"
          />
          <template v-else>
            <i
              class="el-icon-plus avatar-uploader-icon"
              style="margin-top: 70px"
            ></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </template>
          <div class="el-upload__tip" slot="tip">
            只能上传图片文件，大小不超过 200kb
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="名称" prop="link_name">
        <el-input v-model="edit.data.link_name" />
      </el-form-item>
      <el-form-item label="URL" prop="link">
        <el-input v-model="edit.data.link" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="edit.data.notes" />
      </el-form-item>
      <el-form-item label="缩放">
        <el-input-number v-model="edit.data.scale" :min="10" :max="1000" />
      </el-form-item>
      <el-form-item label="底部控制">
        <el-switch
          v-model="edit.data.is_bottom"
          :active-value="1"
          :inactive-value="0"
        />
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="edit.loading || edit.saving"
        @click="onSubmit"
      >
        保存
      </el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { store } from './store'

export default {
  components: {},

  data() {
    return {
      store,
      edit: store.edit,
      rules: {
        icon_url: { required: true, message: '请填写' },
        link_name: { required: true, message: '请填写' },
        link: [
          { required: true, message: '请填写' },
          { type: 'url', message: '请填写正确的URL' }
        ]
      }
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      store.edit.onEditSubmit()
    },

    beforeUpload(file) {
      if (file.size / 1024 > 200) {
        this.$message.error('上传图片大小不能超过 200kb')
        return false
      }
      if (file.type.indexOf('image') === -1) {
        this.$message.error('上传图片格式')
        return false
      }
      return true
    },
    handleUploadChange(file) {
      this.edit.data.icon_url = file.data.url
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';
</style>
