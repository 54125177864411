<template>
  <page-layout>
    <page-header :breadcrumb="['机构开通', '服务开通']" />

    <page-main v-loading="list.loading">
      <search />

      <page-action-bar>
        <el-button type="primary" size="small" @click="onAddClick">
          新增
        </el-button>
      </page-action-bar>

      <el-table :data="list.items">
        <el-table-column label="机构名称">
          <template v-slot="{ row }">
            {{ row.name }}
            <el-tag v-if="row.open_type === 1" size="mini" disable-transitions>
              试用
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="微服务入口FID">
          <template v-slot="{ row }">
            {{ _.map(row.lib_ref, 'fid').join(',') }}
          </template>
        </el-table-column>
        <el-table-column label="机构账号登录FID">
          <template v-slot="{ row }">
            {{ _.map(row.org_info, 'schoolid').join(',') }}
          </template>
        </el-table-column>

        <el-table-column label="机构类型" prop="customer_type" />

        <el-table-column label="行业">
          <template v-slot="{ row }">
            {{ libTypeName(row.lib_type) }}
          </template>
        </el-table-column>

        <el-table-column label="开通时间">
          <template v-slot="scope">
            {{ date(scope.row.created_at) }}
          </template>
        </el-table-column>

        <el-table-column label="服务">
          <template v-slot="{ row }">
            <div class="tag-list">
              <!-- <template v-for="it in LibLoginType">
                <el-tag
                  :key="it.value"
                  v-if="row.login_type?.includes(it.value)"
                  size="small"
                  type="success"
                  disable-transitions
                >
                  {{ it.label }}
                </el-tag>
              </template> -->

              <template v-for="it in LibAppType">
                <el-tag
                  :key="it.value"
                  v-if="row.applications?.includes(it.value)"
                  type="success"
                  size="small"
                  disable-transitions
                >
                  {{ it.label }}
                </el-tag>
              </template>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100px">
          <template v-slot="scope">
            <el-button type="text" @click="onEditClick(scope.row)">
              编辑
            </el-button>
            <el-button type="text" @click="onRemoveClick(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts" setup>
import { date } from '@/admin-shared-modules/utils/format'
import { LibAppType } from '@/consts'
import _ from 'lodash'
import Edit from './lib-edit.component.vue'
import Search from './lib-search.component.vue'
import store from './lib.store'

const _include = _.includes

const { list } = store

store.list.fetch({ reset: true })
store.libTemplate.fetchAll()

function libTypeName(value) {
  //@ts-ignore
  return (
    _.chain(store.libTemplate.options)
      .find({ key: value })
      // @ts-ignore
      .get('value')
      .value()
  )
}

function handleCurrentChange(index) {
  list.index = index
  store.list.fetch()
}
function onAddClick() {
  store.edit.onAdd()
}
function onEditClick(item) {
  store.edit.onEdit(item)
}
async function onRemoveClick(item) {
  store.edit.onRemove(item)
}
</script>

<style scoped lang="scss">
.tag-list {
  > span {
    margin-right: 6px;
    margin-bottom: 4px;
  }
}
</style>
