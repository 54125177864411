import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { GET } from '@/admin-shared-modules/utils/ajax'
import { Ref, computed, reactive, ref, unref, watch } from 'vue'

export function useList<T = any, Q = any>(props: {
  url: string | Ref<string>
  query?: Ref<Q>
  enable?: Ref<boolean>
  pagination?: boolean
}) {
  const index = ref(1)
  const size = ref(20)
  const total = ref(0)
  const loading = ref(false)
  const items = ref<T[]>([])

  const enable = computed(() => {
    return props.enable ? props.enable.value : true
  })

  async function fetch() {
    console.log('useList: fetch')

    const { url, query } = props

    const pager = props.pagination
      ? {
          pageIndex: index.value - 1,
          pageSize: size.value
        }
      : {}

    try {
      loading.value = true
      const { data } = await GET(unref(url), {
        data: {
          ...(query ? (query.value as any) : {}),
          ...pager
        },
        baseURL: '/'
      })

      items.value = data
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      loading.value = false
    }
  }

  // If `enable`, fetch data
  watch(
    enable,
    async enable => {
      if (enable) {
        await fetch()
      }
    },
    { immediate: true }
  )

  // If `query` or `url` changed, reset index [Height Priority]
  watch(
    () => [props.query, props.url],
    () => {
      console.log('useList: query or url changed, reset index')
      index.value = 1
    },
    {
      deep: true,
      flush: 'sync'
    }
  )

  // If `query` or `index` changed, fetch data if `enable`
  watch(
    () => [props.query, index],
    async () => {
      console.log(
        `useList: query or index changed, fetch enable ${enable.value}`
      )
      if (enable.value) {
        await fetch()
      }
    },
    {
      deep: true
    }
  )

  function refetch() {
    fetch()
  }

  const listState = reactive({
    index,
    size,
    total,
    loading,
    items
  })

  return {
    index,
    size,
    total,
    loading,
    items,
    listState,
    fetch,
    refetch
  }
}
