import moment from 'moment'
import { AJAXErrorResult } from './ajax'
import { MessageBoxService } from './message-box.service'

export const parseDateRangeToQuery = (
  key: string,
  dateRange: Date[],
  format = 'YYYY-MM-DD'
) => {
  if (!dateRange) return null

  return {
    [`${key}_begin`]: dateRange[0]
      ? moment(dateRange[0]).startOf('day').format(format)
      : '',
    [`${key}_end`]: dateRange[1]
      ? moment(dateRange[1]).endOf('day').format(format)
      : ''
  }
}

export const defaultErrorHandler = e => {
  console.error(e)
  const ex: AJAXErrorResult = e
  if (ex.handled) return
  MessageBoxService.alert({ type: 'error', message: ex.message })
  throw e
}

export const scrollTop = () => {
  if (document.querySelector('.main')) {
    document.querySelector('.main').scrollTop = 0
  }
}
