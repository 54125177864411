<template>
  <page-layout>
    <page-header :breadcrumb="['统计', '运营数据统计']" />
    <page-main>
      <el-tabs v-model="state.dateType">
        <el-tab-pane label="日报" name="day" />
        <el-tab-pane label="周报" name="week" />
        <el-tab-pane label="月报" name="month" />
      </el-tabs>

      <div class="page-search">
        <template v-if="state.dateType === 'day'">
          <el-date-picker
            v-model="state.currentDate"
            type="date"
            placeholder="选择日期"
          />
        </template>
        <template v-if="state.dateType === 'week'">
          <el-date-picker
            v-model="state.currentDate"
            type="week"
            placeholder="选择周"
            :picker-options="pickerOptions"
          />
        </template>
        <template v-if="state.dateType === 'month'">
          <el-date-picker
            v-model="state.currentDate"
            type="month"
            placeholder="选择月"
          />
        </template>
      </div>

      <div
        class="list"
        v-loading="state.loading"
        v-if="items.active_device_count"
      >
        <div class="item">
          <span class="label">{{ items.active_device_count.label }}:</span
          >{{ items.active_device_count.value }}
        </div>
        <div class="item">
          <span class="label">{{ items.active_library_count.label }}:</span
          >{{ items.active_library_count.value }}
        </div>
        <div class="item">
          <span class="label"
            >{{ items.active_devices_count_by_date.label }}:</span
          >{{ items.active_devices_count_by_date.value }}
        </div>
        <hr />
        <div class="item">
          <span class="label">{{ items.reader_device_user_count.label }}:</span
          >{{ items.reader_device_user_count.value }}
        </div>
        <div class="item">
          <span class="label"
            >{{ items.user_using_device_count_by_date.label }}:</span
          >{{ items.user_using_device_count_by_date.value }}
        </div>
        <div class="item">
          <span class="label">{{ items.new_user_count_by_date.label }}:</span
          >{{ items.new_user_count_by_date.value }}
        </div>
        <hr />
        <div class="item">
          <span class="label">{{ items.study_login_count.label }}:</span
          >{{ items.study_login_count.value }}
        </div>
        <div class="item">
          <span class="label">{{ items.study_login_count_by_date.label }}:</span
          >{{ items.study_login_count_by_date.value }}
        </div>
        <div class="item">
          <span class="label"
            >{{ items.new_bind_user_count_by_date.label }}:</span
          >{{ items.new_bind_user_count_by_date.value }}
        </div>
        <hr />
        <div class="item">
          <span class="label">{{ items.all_lend_book_count.label }}:</span
          >{{ items.all_lend_book_count.value }}
        </div>
        <div class="item">
          <span class="label">{{ items.lend_book_count_by_date.label }}:</span
          >{{ items.lend_book_count_by_date.value }}
        </div>
        <hr />
        <div class="item">
          <span class="label">{{ items.all_reading_time.label }}:</span
          >{{ items.all_reading_time.value }}
        </div>
        <div class="item">
          <span class="label">{{ items.reading_time_by_date.label }}:</span
          >{{ items.reading_time_by_date.value }}
        </div>
        <hr />
        <div class="item">
          <span class="label">{{ items.all_course_push_count.label }}:</span
          >{{ items.all_course_push_count.value }}
        </div>
        <div class="item">
          <span class="label">{{ items.course_push_count_by_date.label }}:</span
          >{{ items.course_push_count_by_date.value }}
        </div>
        <hr />
        <div class="item">
          <span class="label">{{ items.all_lend_device_count.label }}:</span
          >{{ items.all_lend_device_count.value }}
        </div>
        <div class="item">
          <span class="label">{{ items.lend_device_count_by_date.label }}:</span
          >{{ items.lend_device_count_by_date.value }}
        </div>
        <div class="item">
          <span class="label">{{ items.all_return_device_count.label }}:</span
          >{{ items.all_return_device_count.value }}
        </div>
        <div class="item">
          <span class="label"
            >{{ items.return_device_count_by_date.label }}:</span
          >{{ items.return_device_count_by_date.value }}
        </div>
        <hr />
        <div class="item">
          <span class="label">{{ items.all_book_count.label }}:</span
          >{{ items.all_book_count.value }}
        </div>
        <div class="item">
          <span class="label"
            >{{ items.library_public_book_count_by_date.label }}:</span
          >{{ items.library_public_book_count_by_date.value }}
        </div>
        <div class="item">
          <span class="label"
            >{{ items.library_private_book_count.label }}:</span
          >{{ items.library_private_book_count.value }}
        </div>
        <div class="item">
          <span class="label"
            >{{ items.library_private_book_count_by_date.label }}:</span
          >{{ items.library_private_book_count_by_date.value }}
        </div>
        <hr />
        <div class="item">
          <span class="label"
            >{{ items.library_public_project_count.label }}:</span
          >{{ items.library_public_project_count.value }}
        </div>
        <div class="item">
          <span class="label"
            >{{ items.library_private_project_count.label }}:</span
          >{{ items.library_private_project_count.value }}
        </div>
        <div class="item">
          <span class="label"
            >{{ items.library_publish_book_count.label }}:</span
          >{{ items.library_publish_book_count.value }}
        </div>
      </div>
    </page-main>
  </page-layout>
</template>

<script>
import store from './opts-report.store'

export default {
  data() {
    return {
      state: store.state,
      pickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  computed: {
    items() {
      return this.state.data
    }
  },
  watch: {
    'state.dateType'() {
      store.fetch()
    },
    'state.currentDate'() {
      store.fetch()
    }
  },

  mounted() {
    store.fetch()
  }
}
</script>

<style scoped>
.page-search {
  margin-bottom: 24px;
}
.item {
  margin-bottom: 4px;
}
.label {
  /*display: inline-block;*/
  /*vertical-align: middle;*/
  /*min-width: 18em;*/
  margin-right: 1em;
}
</style>
