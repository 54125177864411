<template>
  <el-select
    v-model="currentValue"
    filterable
    allow-create
    remote
    :remote-method="onOrgSelectorQuery"
    default-first-option
    :clearable="clearable"
    :loading="loading"
    placeholder="FID(机构名称)"
    style="width: 100%"
    @change="onChange"
  >
    <el-option
      v-for="item in items"
      :key="item.schoolid"
      :label="
        item.schoolid
          ? item.name
            ? `${item.schoolid}(${item.name})`
            : item.schoolid
          : ''
      "
      :value="item.schoolid"
    />
  </el-select>
</template>

<script lang="ts">
import _ from 'lodash'
import { GET } from '@/admin-shared-modules/utils/ajax'
import { OrgInfoRegex } from '@/views/lib/lib.store'

export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    defaultItem: {
      type: Object,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value,
      items: _.get(this.defaultItem, 'schoolid') ? [this.defaultItem] : [],
      loading: false
    }
  },

  methods: {
    async onOrgSelectorQuery(val) {
      this.loading = true
      const { data } = await GET(`library/organization`, {
        data: {
          keyword: val
        }
      })
      this.items = data
      this.loading = false
    },
    onChange(val) {
      this.$emit('input', val)
      if (_.isNumber(val) && this.items) {
        this.$emit('change', _.cloneDeep(_.find(this.items, { schoolid: val })))
      } else if (_.isString(val)) {
        const execRes = OrgInfoRegex.exec(val)
        if (execRes) {
          const [match, id, name] = execRes
          this.$emit('change', {
            name,
            schoolid: _.parseInt(id),
            user: true
          })
        } else {
          this.$emit('change', { schoolid: val })
        }
      }
    }
  }
}
</script>

<style scoped></style>
