<template>
  <div>
    <el-form ref="form" label-width="10em">
      <el-form-item label="借阅图书">
        <el-input-number
          v-model="edit.data.lend_times"
          :precision="1"
          :min="1"
          controls-position="right"
          size="small"
        />
        倍
      </el-form-item>
      <el-form-item label="阅读图书">
        <el-input-number
          v-model="edit.data.read_book_times"
          :precision="1"
          :min="1"
          controls-position="right"
          size="small"
        />
        倍
      </el-form-item>
      <el-form-item label="阅读时长">
        <el-input-number
          v-model="edit.data.read_time_times"
          :precision="1"
          :min="1"
          controls-position="right"
          size="small"
        />
        倍
      </el-form-item>
      <el-form-item label="总借阅图书偏移">
        <el-input-number
          v-model="edit.data.lend_count"
          controls-position="right"
          size="small"
        />
        本
      </el-form-item>
      <el-form-item label="总阅读时长偏移">
        <el-input-number
          v-model="edit.data.read_time_count"
          controls-position="right"
          size="small"
        />
        小时
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts">
import store from '../lib.store'

export default {
  data() {
    return {
      edit: store.edit
    }
  },
  methods: {
    getForm() {
      return this.$refs.form
    }
  }
}
</script>

<style scoped></style>
