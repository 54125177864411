<template>
  <el-upload
    class="image-uploader"
    style="width: 200px; height: auto; min-height: 50px"
    accept="image/*"
    :action="url"
    :show-file-list="false"
    :before-upload="beforeUpload"
    :on-success="onSuccess"
    :on-error="onError"
  >
    <img v-if="value" :src="value" />
    <i v-else-if="isUploading" class="el-icon-loading uploader-icon" />
    <i v-else class="el-icon-plus uploader-icon" />
  </el-upload>
</template>

<script lang="ts">
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import _ from 'lodash'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    url: {
      default: '/api/uploads'
    },
    value: {
      type: String
    }
  },
  setup(props, { emit }) {
    const isUploading = ref(false)

    return {
      isUploading,

      beforeUpload(file: File) {
        if (isUploading.value) {
          return
        }
        if (!/^image\//.test(file.type)) {
          MessageService.open({ type: 'error', message: '图片格式无效' })
          return false
        }
        if (file.size / 1024 > 300) {
          MessageService.open({
            type: 'error',
            message: '图片大小不能超过300kb'
          })
          return false
        }
        isUploading.value = true
        return true
      },
      onSuccess(res) {
        isUploading.value = false
        emit('input', _.get(res, 'data.url'))
      },
      onError(err) {
        isUploading.value = false
        MessageBoxService.alert({ type: 'error', message: err })
      }
    }
  }
})
</script>

<style scoped></style>
