import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import 'moment/locale/zh-cn'
import momentDurationFormatSetup from 'moment-duration-format'
import { GET } from '@/admin-shared-modules/utils/ajax'

momentDurationFormatSetup(moment)
moment.locale('zh-cn')

class OptsReportStore {
  state = {
    dateType: 'day' as 'day' | 'week' | 'month',
    currentDate: moment().toDate(),
    data: {},
    loading: false
  }

  async fetch() {
    this.state.loading = true
    this.state.data = {}

    const res = await GET('data_statistic', {
      data: this.getDateRange()
    })
    this.state.data = parseData(res.data, this.state.dateType)
    this.state.loading = false
  }

  private getDateRange() {
    const { dateType, currentDate } = this.state
    const format = (date: Date) => moment(date).format('YYYY-MM-DD')

    if (dateType === 'day') {
      return {
        date_begin: format(currentDate),
        date_end: format(currentDate)
      }
    } else if (dateType === 'week') {
      return {
        date_begin: format(moment(currentDate).startOf('week').toDate()),
        date_end: format(moment(currentDate).endOf('week').toDate())
      }
    } else {
      return {
        date_begin: format(moment(currentDate).startOf('month').toDate()),
        date_end: format(moment(currentDate).endOf('month').toDate())
      }
    }
  }
}

const parseData = (src, dateType) => {
  const dataKey: {
    [key: string]: {
      label: string
      type?: 'number' | 'date'
      appendDateToLabel?: boolean
    }
  } = {
    active_device_count: { label: '在用设备总数' },
    active_library_count: { label: '在用图书馆数' },
    active_devices_count_by_date: { label: '活跃设备数' },
    //reader
    reader_device_user_count: { label: '阅读本服务读者总数' },
    user_using_device_count_by_date: {
      label: '使用读者',
      appendDateToLabel: true
    },
    new_user_count_by_date: { label: '新增读者', appendDateToLabel: true },
    //cx user
    study_login_count: { label: '学习通登录阅读本用户总数' },
    study_login_count_by_date: { label: '登录用户数', appendDateToLabel: true },
    new_bind_user_count_by_date: {
      label: '新增绑定用户数',
      appendDateToLabel: true
    },
    // book push
    all_lend_book_count: { label: '阅读本总借阅图书次数' },
    lend_book_count_by_date: {
      label: '新增借阅图书次数',
      appendDateToLabel: true
    },
    // read time
    all_reading_time: { label: '阅读本总阅读时长', type: 'date' },
    reading_time_by_date: {
      label: '新增阅读时长',
      type: 'date',
      appendDateToLabel: true
    },
    // share from cx-study
    all_course_push_count: { label: '学习通专题转发到阅读本总次数' },
    course_push_count_by_date: {
      label: '新增转发专题次数',
      appendDateToLabel: true
    },
    // device lend
    all_lend_device_count: { label: '阅读本总借出次数' },
    lend_device_count_by_date: { label: '今日新增借出' },
    all_return_device_count: { label: '阅读本总归还次数' },
    return_device_count_by_date: { label: '新增归还' },
    // res
    all_book_count: { label: '书城图书资源总数' },
    library_public_book_count_by_date: { label: '新增公共图书数' },
    library_private_book_count: { label: '图书馆自上传图书总数' },
    library_private_book_count_by_date: { label: '新增图书馆自有图书数' },
    // topic
    library_public_project_count: { label: '公共专题总数' },
    library_private_project_count: { label: '图书馆自建专题总数' },
    library_publish_book_count: { label: '图书馆预置图书总数' }
  }

  return _.mapValues(dataKey, (config, key) => {
    const { label, type, appendDateToLabel } = config
    let displayLabel = label
    let value = src[key]

    if (appendDateToLabel) {
      if (dateType === 'day') {
        displayLabel = '今日' + displayLabel
      }
      if (dateType === 'week') {
        displayLabel = '本周' + displayLabel
      }
      if (dateType === 'month') {
        displayLabel = '本月' + displayLabel
      }
    }

    if (type === 'date') {
      value = durationFormat(value)
    } else {
      value = numeral(value).format('0,0')
    }
    return {
      ...config,
      displayLabel,
      value
    }
  })
}

const durationFormat = val => {
  function customTemplate() {
    return this.duration.asMinutes() >= 60 ? 'h小时m分钟' : 'm分钟'
  }
  // @ts-ignore
  return moment.duration(val, 'minutes').format(customTemplate, {
    trim: false
  })
}

export default new OptsReportStore()
