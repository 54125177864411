<template>
  <el-form
    ref="form"
    :model="edit.data"
    :rules="rules"
    label-width="9em"
    v-loading="edit.loading"
    style="padding-right: 30px"
  >
    <div class="section">
      <h3 class="hd">登录与服务</h3>
      <div class="bd">
        <el-form-item label="阅读本登录" prop="login_type">
          <ElCheckboxGroup
            v-model="edit.data.login_type"
            @change="loginTypeChange"
          >
            <ElCheckbox
              v-for="it in LibLoginType"
              :key="it.value"
              :label="it.value"
              :disabled="it.value !== 3 && edit.data.login_type?.includes(3)"
            >
              {{ it.label }}
            </ElCheckbox>
          </ElCheckboxGroup>
        </el-form-item>
        <el-form-item
          v-if="edit.data.login_type?.includes(6)"
          label="第三方登录地址"
          prop="third_login_url"
        >
          <ElInput v-model.trim="edit.data.third_login_url" />
        </el-form-item>

        <device-org-ref-group
          v-if="~edit.data.login_type.indexOf(2)"
          v-model="edit.data.org_info"
        />

        <ElFormItem label="应用" prop="applications">
          <ElCheckboxGroup v-model="edit.data.applications">
            <ElCheckbox
              v-for="it in LibAppType"
              :key="it.value"
              :label="it.value"
              :style="{ minWidth: '8em' }"
            >
              {{ it.label }}
            </ElCheckbox>
          </ElCheckboxGroup>

          <ElCheckbox @change="onAppCheckAllChange">全选</ElCheckbox>
        </ElFormItem>

        <el-form-item label="USB传书" prop="is_usb">
          <el-switch v-model="edit.data.is_usb" />
        </el-form-item>

        <el-form-item
          label="管控设置项"
          prop="manage_config"
          v-if="hasDeviceReader"
        >
          <el-switch
            v-model="edit.data.manage_config"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item
          label="管控设置项密码"
          prop="manage_config_pwd"
          v-if="hasDeviceReader && edit.data.manage_config === 1"
        >
          <el-input
            v-model="edit.data.manage_config_pwd"
            style="width: 200px"
          />
        </el-form-item>
      </div>
    </div>

    <div class="section" v-if="hasDeviceReader">
      <h3 class="hd">借还管理</h3>
      <div class="bd">
        <el-form-item label="借还方式" prop="lend_way" v-if="hasDeviceReader">
          <el-checkbox-group v-model="edit.data.lend_way">
            <el-checkbox label="BY_SHELF"> 借阅柜自助借阅 </el-checkbox>
            <el-checkbox label="BY_MANUAL"> 人工借阅 </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item
          v-if="hasDeviceReader && ~edit.data.lend_way.indexOf('BY_SHELF')"
          label="显示借阅时间"
          prop="show_lend_info"
        >
          <el-switch v-model="edit.data.show_lend_info" />
        </el-form-item>

        <el-form-item
          v-if="hasDeviceReader"
          label="借阅名单管理"
          prop="is_show_borrow"
        >
          <el-switch v-model="edit.data.is_show_borrow" />
        </el-form-item>

        <el-form-item
          v-if="hasDeviceReader"
          label="显示借阅信息"
          prop="show_lend_info"
        >
          <el-switch v-model="edit.data.show_lend_info" />
        </el-form-item>

        <el-form-item
          v-if="hasDeviceReader"
          label="超期自动锁定"
          prop="is_lock"
        >
          <el-switch v-model="edit.data.is_lock" />
        </el-form-item>
      </div>
    </div>

    <div class="section">
      <h3 class="hd">Logo、应用定制</h3>
      <div class="bd">
        <template v-if="!edit.isEdit">
          <el-form-item label="Logo" style="margin-bottom: 0">
            <el-radio-group v-model="edit.data.logo_source_type" size="small">
              <el-radio :label="0"> 默认 </el-radio>
              <el-radio :label="1"> 定制 </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="edit.data.logo_source_type" prop="logo">
            <image-upload v-model="edit.data.logo" />
          </el-form-item>
        </template>

        <template v-else>
          <el-form-item label="Logo" prop="logo">
            <image-upload v-model="edit.data.logo" />
          </el-form-item>
        </template>

        <template v-if="!edit.isEdit">
          <el-form-item label="应用">
            <el-radio-group
              v-model="edit.data.app_image_source_type"
              size="small"
            >
              <el-radio :label="0"> 默认 </el-radio>
              <el-radio :label="1"> 定制 </el-radio>
            </el-radio-group>
          </el-form-item>

          <template v-if="edit.data.app_image_source_type">
            <el-form-item label="书城别名" prop="shop_alias">
              <el-input
                v-model.trim="edit.data.shop_alias"
                style="width: 200px"
              />
            </el-form-item>
            <el-form-item label="应用名称" prop="app_name">
              <el-input
                v-model.trim="edit.data.app_name"
                style="width: 200px"
              />
            </el-form-item>
            <el-form-item label="下载二维码" prop="app_image">
              <image-upload v-model="edit.data.app_image" />
            </el-form-item>
            <el-form-item label="下载地址" prop="app_download_url">
              <el-input
                v-model.trim="edit.data.app_download_url"
                style="width: 500px"
              />
            </el-form-item>
          </template>
        </template>

        <template v-else>
          <el-form-item label="书城别名" prop="shop_alias">
            <el-input
              v-model.trim="edit.data.shop_alias"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="应用名称" prop="app_name">
            <el-input v-model.trim="edit.data.app_name" style="width: 200px" />
          </el-form-item>
          <el-form-item label="下载二维码" prop="app_image">
            <image-upload v-model="edit.data.app_image" />
          </el-form-item>
          <el-form-item label="下载地址" prop="app_download_url">
            <el-input
              v-model.trim="edit.data.app_download_url"
              style="width: 500px"
            />
          </el-form-item>
        </template>
      </div>
    </div>
  </el-form>
</template>

<script setup lang="ts">
import ImageUpload from '@/components/image-upload.vue'
import { LibAppType, LibLoginType } from '@/consts'
import _ from 'lodash'
import { computed, defineExpose, watch } from 'vue'
import store from '../lib.store'
import DeviceOrgRefGroup from '../shared/device-org-ref-group.vue'

const BaseRequired = { required: true, message: '请填写' }

const edit = store.edit

watch(
  () => edit.data.lend_way,
  () => {
    if (!~edit.data.lend_way.indexOf('BY_SHELF')) {
      edit.data.show_lend_info = false
    }
  }
)

const rules = {
  device_type: [{ type: 'array', ...BaseRequired, message: '请选择' }],
  login_type: [{ type: 'array', ...BaseRequired, message: '请选择' }],
  third_login_url: [BaseRequired, { type: 'url', message: '请输入正确的URL' }],
  manage_config_pwd: [{ ...BaseRequired }, { min: 5, message: '至少5位密码' }],
  app_name: [{ ...BaseRequired }],
  app_image: [{ ...BaseRequired, message: '请选择' }]
}

const hasDeviceReader = computed(() => {
  return _.includes(edit.data.device_type, 'READER')
})

function loginTypeChange(val) {
  if (~val.indexOf(3)) {
    edit.data.login_type = [3]
  }
}
function getForm() {
  return this.$refs.form
}
defineExpose({ getForm })

function onAppCheckAllChange(checked) {
  if (checked) {
    edit.data.applications = LibAppType.map(it => it.value)
  } else {
    edit.data.applications = []
  }
}
</script>

<style scoped lang="scss">
.section {
  margin-bottom: 20px;

  .hd {
    font-weight: bold;
  }
}
</style>
