<template>
  <el-select
    v-model="currentValue"
    :size="size"
    :clearable="clearable"
    :disabled="disabled"
    :filterable="filterable"
    popper-class="select-popper__grid-content"
  >
    <el-option
      v-for="(item, index) in RegionData"
      :key="item.id"
      :value="nameBaseValue ? item.name : item.id"
      :label="item.name"
    />
  </el-select>
</template>

<script>
import { RegionData } from '../../utils/region'

export default {
  props: {
    value: { required: true },
    size: { type: String, default: null },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    filterable: { type: Boolean, default: true },
    nameBaseValue: { type: Boolean, default: true }
  },

  data() {
    return {
      currentValue: this.value,
      RegionData
    }
  },
  watch: {
    value(v) {
      this.currentValue = v
    },
    currentValue(v) {
      this.$emit('input', v)
    }
  }
}
</script>
