import { Message } from 'element-ui'

export interface MessageServiceOptions {
  message: string
  type?: 'success' | 'warning' | 'info' | 'error'
}

export class MessageService {
  static open(options: MessageServiceOptions) {
    if (!options.type) {
      options.type = 'success'
    }
    Message(options)
  }
}
