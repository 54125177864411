<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    class="edit-dialog"
    top="8vh"
    width="600px"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      v-if="edit.visible"
      :model="edit.data"
      :rules="rules"
      label-width="8em"
      v-loading="edit.loading"
    >
      <el-form-item label="用户名" prop="name">
        <el-input v-model.trim="edit.data.name" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model.trim="edit.data.phone" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button type="primary" :loading="edit.loading" @click="onSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import store from './maintain-account.store'
import { defaultErrorHandler } from '../../admin-shared-modules/utils'

export default {
  data() {
    return {
      edit: store.edit,

      rules: {
        name: [{ required: true, message: '请输入' }],
        phone: [{ required: true, message: '请输入' }]
      }
    }
  },

  methods: {
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        await store.onEditSubmit()
        this.edit.visible = false
      })
    }
  }
}
</script>
