<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import AppStore from './store'
import { GET } from '@/admin-shared-modules/utils/ajax'

export default {
  data() {
    return {
      state: AppStore.state
    }
  }
}
</script>

<style lang="scss">
@import 'admin-shared-modules/theme/app';
@import 'styles/app';
</style>
