import _ from 'lodash'
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import { scrollTop } from '@/admin-shared-modules/utils'
import AppStore from '@/store'
import { GET } from '@/admin-shared-modules/utils/ajax'

import DefaultLayout from '@/layouts/default/default-layout.vue'
import PrintLayout from '@/layouts/print/print-preview-layout.vue'
import SignIn from '@/views/passport/sign-in.component.vue'
import Page401 from '@/layouts/401.vue'
import { getRouter } from '@/router/config'
import { isDEV } from '@/consts'
const DevicePrintPreview = () =>
  import(
    /* webpackChunkName: "kindle" */ '@/views/device-print/device-print.component.vue'
  )

const router = new Router({
  routes: [
    {
      path: '/sign-in',
      name: 'signIn',
      component: SignIn,
      meta: { auth: false }
    },
    {
      path: '/u',
      component: DefaultLayout,
      children: getRouter()
    },
    {
      path: '/p',
      component: PrintLayout,
      children: [
        {
          path: 'device',
          name: 'devicePrintPreview',
          component: DevicePrintPreview
        }
      ]
    },
    { path: '/401', component: Page401, meta: { auth: false } },
    { path: '', redirect: '/u' }
  ]
})

router.beforeEach(async (to, from, next) => {
  scrollTop()

  if (_.isBoolean(to.meta.auth) && !to.meta.auth) {
    next()
    return
  }

  let isSignIn = !!AppStore.state.user.data
  if (!isSignIn) {
    const res: any = await GET(`admin/current`, {})
    AppStore.state.user.data = res.data
    isSignIn = true
  }
  if (!isSignIn) {
    next({ name: 'signIn' })
    return
  }

  const menus = _.get(AppStore.state.user.data, 'menues')
  const key = _.chain(to.path).split('/').last().value()
  if (to.path !== '/u' && !_.includes(menus, key)) {
    next('/u')
    return
  }

  next()
})

export default router
