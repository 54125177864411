<template>
  <el-header class="app-header">
    <div class="left">
      <div class="nav-collapse-trigger" @click="triggerNav">
        <i v-show="isNavOpen" class="el-icon-ant-menu-fold" />
        <i v-show="!isNavOpen" class="el-icon-ant-menu-unfold" />
      </div>
    </div>
    <div class="right">
      <el-menu class="user-menu" mode="horizontal">
        <el-submenu index="1" popper-class="user-submenu">
          <template slot="title">
            {{ userName || 'Admin' }}
          </template>
          <el-menu-item index="1-1" @click="signOut"> 注销 </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </el-header>
</template>

<script lang="ts">
import _ from 'lodash'
import AppStore from '../../store'
import { GET } from '../../admin-shared-modules/utils/ajax'

export default {
  computed: {
    userName() {
      return _.get(AppStore.state.user.data, 'user_name')
    },
    isNavOpen() {
      return AppStore.state.ui.openNav
    }
  },
  methods: {
    triggerNav() {
      AppStore.state.ui.openNav = !AppStore.state.ui.openNav
    },
    async signOut() {
      await GET(`admin/logout`, {})
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  display: flex;
  align-items: center;
  height: 64px !important;
  padding: 0;
  background-color: #fff;
  border-bottom: solid 1px #e6e6e6;
  z-index: 1;
  .left {
    flex: 1;
  }
  .right {
  }

  .nav-collapse-trigger {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    width: 64px;
    height: 64px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      /*background: #e6f7ff;*/
    }
    i {
      font-size: 18px !important;
      color: #888;
    }
  }
}
.user-menu {
  border: 0 !important;
  background-color: #fff;
  .el-submenu__title {
    transition: none;
    background-color: #fff;
  }
  &::before,
  &::after {
    display: none;
  }
}
</style>
<style lang="scss">
.user-submenu {
  .el-menu--popup {
    margin-top: 0;
  }
}
</style>
