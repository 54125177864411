import Vue from 'vue'

import Element from 'element-ui'
import VueParticles from 'vue-particles'
Vue.use(Element)
Vue.use(VueParticles)

import PageActionBar from '@/admin-shared-modules/layout/page-action-bar.vue'
import PageHeader from '@/admin-shared-modules/layout/page-header.vue'
import PageLayout from '@/admin-shared-modules/layout/page-layout.vue'
import PageMain from '@/admin-shared-modules/layout/page-main.vue'
import ExpressCodeLink from './components/express-code-link.vue'

Vue.component(PageLayout.name, PageLayout)
Vue.component(PageHeader.name, PageHeader)
Vue.component(PageMain.name, PageMain)
Vue.component(PageActionBar.name, PageActionBar)
Vue.component(ExpressCodeLink.name, ExpressCodeLink)

import {
  date,
  fileSize,
  html2text,
  humanizeDuration,
  join,
  limitLength,
  number,
  splitByLength
} from '@/admin-shared-modules/utils/format'

Vue.filter('date', date)
Vue.filter('join', join)
Vue.filter('splitByLength', splitByLength)
Vue.filter('humanizeDuration', humanizeDuration)
Vue.filter('fileSize', fileSize)
Vue.filter('number', number)
Vue.filter('limitLength', limitLength)
Vue.filter('html2text', html2text)
