<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    width="500px"
  >
    <el-form
      v-if="edit.visible"
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="角色名称" prop="name">
        <el-input v-model="edit.data.name" />
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="edit.data.intro" />
      </el-form-item>
      <el-form-item label="权限">
        <el-tree
          ref="tree"
          :data="menuData"
          show-checkbox
          node-key="key"
          :default-checked-keys="edit.data.menues"
          :props="{ label: 'title' }"
          style="margin-top: 8px"
        />
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="edit.loading || edit.saving"
        @click="onSubmit"
      >
        保存
      </el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { store } from './store'
import { getMenuItems } from '@/router/config'

export default {
  components: {},

  data() {
    return {
      store,
      edit: store.edit,
      rules: {
        name: { required: true, message: '请填写' }
      },
      menuData: getMenuItems(false)
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      this.edit.data.menues = this.$refs['tree'].getCheckedKeys(true)
      store.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';
</style>
