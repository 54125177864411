<template>
  <div class="page-action-bar">
    <div class="left">
      <slot />
    </div>
    <slot name="right" />
  </div>
</template>

<script>
export default {
  name: 'PageActionBar'
}
</script>

<style scoped lang="scss">
.page-action-bar {
  display: flex;
  margin: 8px 0 16px;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    flex: auto;
  }
}
</style>
