import _ from 'lodash'
import { DELETE, GET, POST, PUT } from '@/admin-shared-modules/utils/ajax'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'

export interface LibAccount {
  id: number
  name: string
  phone: string
  password: string
  library_id: number
}
export type LibAccountFromData = LibAccount

class Store {
  lib = {
    id: null
  }
  list = {
    items: [] as LibAccount[],
    index: 1,
    size: 10,
    total: 0,
    loading: false
  }
  edit = {
    visible: false,
    isEdit: false,
    data: {} as LibAccountFromData,
    loading: false
  }

  async fetch(reset = false) {
    if (reset) this.list.index = 1
    this.list.loading = true
    this.list.items = []
    try {
      const res = await GET('library/admin', {
        data: {
          pageIndex: this.list.index - 1,
          pageSize: this.list.size,
          library_id: this.lib.id
        }
      })
      this.list.items = res.data.items
      this.list.total = res.data.totalCount
    } catch (e) {
      console.error(e)
    } finally {
      this.list.loading = false
    }
  }

  onAdd() {
    this.edit.data = {} as LibAccountFromData
    this.edit.isEdit = false
    this.edit.visible = true
  }
  onEdit(item: LibAccount) {
    this.edit.data = _.cloneDeep(item)
    this.edit.isEdit = true
    this.edit.visible = true
  }
  async onEditSubmit() {
    const data = {
      ...this.edit.data,
      library_id: this.lib.id
    }
    const requestMethod = this.edit.isEdit ? PUT : POST
    const url = this.edit.isEdit
      ? `library/admin/${this.edit.data.id}`
      : 'library/admin'

    this.edit.loading = true
    try {
      const res = await requestMethod(url, {
        data
      })
      this.edit.visible = false
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.edit.loading = false
    }
  }
  async onRemove(item: LibAccount) {
    try {
      await DELETE(`library/admin/${item.id}`, {})
      this.fetch()
    } catch (e) {
      defaultErrorHandler(e)
    }
  }
}

export default new Store()
