import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { DELETE, GET, POST, PUT } from '@/admin-shared-modules/utils/ajax'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { ListFetchOptions } from '@/core/typing'
import _ from 'lodash'

export abstract class AbstractEditService<TCT = any, T = any, P = Partial<T>> {
  visible = false
  isEdit = false
  data = {} as T
  params = {} as P
  loading = false
  saving = false

  $formUtilRef: any = null

  constructor(protected context: TCT) {}

  onAdd(params?: P) {
    this.data = _.cloneDeep(this.getDefaultFormData(params)) as any
    this.params = params
    this.isEdit = false
    this.saving = false
    this.visible = true
  }

  getDefaultFormData(params: P): Partial<T> {
    return params || {}
  }

  async onEdit(params: P) {
    this.params = params
    this.isEdit = true
    this.saving = false
    this.data = {} as T

    if (this.loading) {
      return
    }

    try {
      let data = null
      if (this.getFetchURL()) {
        data = await this.fetchFormData()
      } else {
        data = _.cloneDeep(params)
      }

      this.data = await this.parseFetchedFormData(data)
      this.visible = true
    } catch (e) {
      defaultErrorHandler(e)
    }
  }

  async onEditSubmit() {
    if (this.saving) {
      return
    }
    this.saving = true
    const data = this.getSubmitData()
    try {
      await this.getSubmitMethod()(this.getSubmitURL(), {
        data
      })
      this.visible = false
      this.requestListReload()
      MessageService.open({ message: '保存成功' })
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.saving = false
    }
  }

  async fetchFormData(): Promise<T> {
    this.loading = true
    const res = await GET(this.getFetchURL() as string, {
      data: this.getFetchParams()
    })
    this.loading = false
    return res.data || res
  }

  getFetchParams(): any {
    return {}
  }

  parseFetchedFormData(data: T) {
    return data
  }

  getSubmitData(): any {
    return this.data
  }

  getSubmitMethod() {
    return this.isEdit ? PUT : POST
  }

  async onRemove(item: P) {
    await MessageBoxService.confirm({ message: '确认删除' })

    this.params = item as any
    try {
      await DELETE(this.getRemoveURL(), {
        data: this.getRemoveParams(item)
      })
      this.requestListReload({ fixIndex: true })
      MessageService.open({ message: '删除成功' })
    } catch (e) {
      defaultErrorHandler(e)
    }
  }
  getRemoveParams(item?: P) {
    return {} as any
  }

  async validateForm() {
    const { $formUtilRef } = this
    return new Promise((resolve, reject) => {
      if (!$formUtilRef) {
        resolve(null)
      }
      $formUtilRef.validateFields(err => {
        if (err) {
          reject()
        } else {
          resolve(null)
        }
      })
    })
  }

  abstract getFetchURL(): string | boolean
  abstract getSubmitURL(): string
  abstract getRemoveURL(): string
  abstract requestListReload(option?: ListFetchOptions): void
}
