<template>
  <div>
    <page-action-bar>
      <el-button type="primary" size="small" @click="onAddClick">
        新增
      </el-button>
    </page-action-bar>

    <el-table :data="list.items" size="small" v-loading="list.loading">
      <el-table-column label="用户姓名" prop="name" />
      <el-table-column label="电话" prop="phone" />
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          {{ scope.row.created_at | date }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="100px">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onEditClick(scope.row)">
            编辑
          </el-button>
          <el-button type="text" size="small" @click="onRemoveCLick(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="list.index"
      :total="list.total"
      :page-size="list.size"
      layout="total, prev, pager, next"
      background
    />

    <edit />
  </div>
</template>

<script lang="ts">
import store from './account.store'
import Edit from './account-edit.component.vue'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'

export default {
  components: { Edit },

  props: {
    libraryId: { require: true, type: Number }
  },
  data() {
    return {
      state: store,
      list: store.list
    }
  },
  watch: {
    libraryId() {
      store.lib.id = this.libraryId
      store.fetch(true)
    }
  },

  mounted() {
    store.lib.id = this.libraryId
    store.fetch(true)
  },

  methods: {
    handleCurrentChange(index) {
      this.list.index = index
      store.fetch()
    },

    onAddClick() {
      store.onAdd()
    },
    onEditClick(item) {
      store.onEdit(item)
    },
    async onRemoveCLick(item) {
      await MessageBoxService.confirm({ message: '确认删除' })
      store.onRemove(item)
    }
  }
}
</script>
