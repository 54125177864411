<template>
  <page-layout>
    <page-header :breadcrumb="['系统', '运维账号管理']" />

    <page-main v-loading="list.loading">
      <search />

      <page-action-bar>
        <el-button type="primary" size="small" @click="onAddClick">
          新增
        </el-button>
      </page-action-bar>

      <el-table ref="table" :data="list.items">
        <el-table-column type="index" width="50" />

        <el-table-column label="用户名">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="手机号">
          <template slot-scope="scope">
            {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | date('YYYY/MM/DD') }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-button type="text" @click="onEditClick(scope.row)">
              编辑
            </el-button>
            <el-button type="text" @click="onRemoveClick(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import store from './maintain-account.store'
import Search from './maintain-account-search.component.vue'
import Edit from './maintain-account-edit.component.vue'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'

export default {
  components: { Search, Edit },
  data() {
    return {
      list: store.list
    }
  },

  mounted() {
    store.fetch()
  },

  methods: {
    async handleCurrentChange(index) {
      store.fetch()
    },
    onAddClick() {
      store.onAdd()
    },
    onEditClick(item) {
      store.onEdit(item)
    },
    async onRemoveClick(item) {
      await MessageBoxService.confirm({ message: '确认删除' })
      store.onRemove(item)
    }
  }
}
</script>
