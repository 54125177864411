import _ from 'lodash'
import { ListState } from '@/admin-shared-modules/typings'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { GET } from '@/admin-shared-modules/utils/ajax'

class FeedbackStore {
  list: ListState<any> = {
    items: [],
    index: 1,
    total: 0,
    size: 50,
    loading: false
  }

  async fetch(reset = false) {
    if (reset) this.list.index = 1

    this.list.loading = true
    this.list.items = []
    try {
      const res = await GET(`device/feedback`, {
        data: {
          pageIndex: this.list.index - 1,
          pageSize: this.list.size
        }
      })
      this.list.items = _.map(res.data.items, item => {
        return {
          ...item,
          _device: {}
        }
      })
      this.list.total = res.data.totalCount
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.list.loading = false
    }
  }

  async fetchFeedbackDevice(item) {
    try {
      const res = await GET(`device/zhuyun`, {
        data: {
          code: item.device_code
        }
      })
      item._device = res.data.padbook
    } catch (e) {
      defaultErrorHandler(e)
    }
  }
}

export default new FeedbackStore()
