export const DeviceBorrowingStateNames = {
  all: '全部',
  lend: '借出',
  store: '在馆'
}

export const LibDeviceTypeMap = {
  READER: 1,
  KINDLE: 2
}

export const LibLendOutTypeMap = {
  NO_READER: 0, //无阅读本
  BY_SHELF: 1, //借还柜
  BY_MANUAL: 2 //人工借还
}

export const BaseRequired = { required: true, message: '请填写' }
