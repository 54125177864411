<template>
  <a :href="url" rel="noopener noreferrer" target="_blank">{{ text }}</a>
</template>
<script>
export default {
  name: 'ExpressCodeLink',
  props: {
    code: {},
    text: { default: '物流查询' }
  },
  computed: {
    url() {
      return `https://m.ickd.cn/result.html#?no=${this.code}&com=auto`
    }
  }
}
</script>
