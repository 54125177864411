<template>
  <el-form
    ref="form"
    :model="edit.data"
    label-width="8em"
    v-loading="edit.loading"
  >
    <div class="section">
      <h3 class="hd">登录与服务</h3>
      <div class="bd">
        <ElFormItem label="登录方式" prop="login_type">
          <ElCheckboxGroup
            v-model="edit.data.login_type"
            @change="loginTypeChange"
          >
            <ElCheckbox
              v-for="it in LibLoginType.filter(it => it.value !== 6)"
              :key="it.value"
              :label="it.value"
              :disabled="it.value !== 3 && edit.data.login_type?.includes(3)"
            >
              {{ it.label }}
            </ElCheckbox>
          </ElCheckboxGroup>
        </ElFormItem>

        <ElFormItem label="应用" prop="applications">
          <ElCheckboxGroup v-model="edit.data.applications">
            <ElCheckbox
              v-for="it in LibAppType"
              :key="it.value"
              :label="it.value"
            >
              {{ it.label }}
            </ElCheckbox>
          </ElCheckboxGroup>
        </ElFormItem>
      </div>
    </div>

    <div class="section">
      <h3 class="hd">Logo、应用定制</h3>
      <div class="bd">
        <el-form-item label="Logo">
          <image-upload v-model="edit.data.logo" />
        </el-form-item>
        <el-form-item label="应用名称">
          <el-input v-model.trim="edit.data.app_name" style="width: 200px" />
        </el-form-item>
        <el-form-item label="下载二维码">
          <image-upload v-model="edit.data.app_image" />
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script lang="ts" setup>
import ImageUpload from '@/components/image-upload.vue'
import { LibAppType, LibLoginType } from '@/consts'
import { store } from '../store'

const edit = store.edit

const loginTypeChange = val => {
  if (val && ~val.indexOf(3)) {
    edit.data.login_type = [3]
  }
}
</script>

<style scoped lang="scss">
.section {
  margin-bottom: 20px;

  .hd {
    font-weight: bold;
  }
}
</style>
