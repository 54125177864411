import { render, staticRenderFns } from "./lib-edit.component.vue?vue&type=template&id=e45e3e8e&scoped=true"
import script from "./lib-edit.component.vue?vue&type=script&setup=true&lang=ts"
export * from "./lib-edit.component.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./lib.style.scss?vue&type=style&index=0&id=e45e3e8e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e45e3e8e",
  null
  
)

export default component.exports