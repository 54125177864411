import { reactive } from 'vue'
import { GET } from './admin-shared-modules/utils/ajax'

const AppStore = {
  state: {
    user: {
      data: null
    },
    lib: {
      data: null
    },
    ui: {
      openNav: true,
      notification: {}
    },
    deviceTypes: {
      data: []
    }
  }
}

export default reactive(AppStore)

export async function fetchDeviceTypes() {
  const { data } = await GET('app_version/device_types', {})
  AppStore.state.deviceTypes.data = data
}
