<template>
  <div style="margin-bottom: 16px">
    <el-form-item label="阅读本登录FID" prop="org_info">
      <el-form-item
        v-for="(item, index) in currentValue"
        :key="index"
        :prop="`org_info.${index}`"
        :rules="[
          {
            type: 'object',
            fields: {
              schoolid: { validator: OrgInfoValidator }
            }
          }
        ]"
        style="margin-bottom: 22px"
      >
        <div class="group">
          <cx-org-selector
            v-model="item.schoolid"
            :default-item="item.schoolid ? item : null"
            @change="org => onChange(org, index)"
            clearable
          />
          <div v-if="index" class="action">
            <el-button type="text" @click="onRemove">
              <i class="el-icon-delete" /> 删除
            </el-button>
          </div>
        </div>
      </el-form-item>
      <el-button type="primary" size="mini" @click="onAdd"> 新增FID </el-button>
    </el-form-item>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import CxOrgSelector from './cx-org-selector.vue'
import { MessageBoxService } from '@/admin-shared-modules/utils/message-box.service'
import { OrgInfoRegex } from '@/views/lib/lib.store'

const getDefaultItem = () => {
  return {
    schoolid: null,
    name: null
  }
}

export default {
  components: {
    CxOrgSelector
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    const OrgInfoValidator = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请填写'))
      }
      if (_.isNumber(value)) {
        return callback()
      }
      if (OrgInfoRegex.test(value)) {
        callback()
      } else {
        callback(new Error('格式错误, 请输入「FID(机构名称)」'))
      }
    }

    return {
      currentValue: _.size(this.value) ? this.value : [getDefaultItem()],
      OrgInfoValidator
    }
  },

  methods: {
    onChange(org, index) {
      this.currentValue[index] = org
      this.emit()
    },
    onAdd() {
      this.currentValue.push(getDefaultItem())
    },
    async onRemove(index) {
      await MessageBoxService.confirm({
        type: 'warning',
        message: '确认删除'
      })
      this.currentValue.splice(index, 1)
      this.emit()
    },
    emit() {
      this.$emit('input', this.currentValue)
    }
  }
}
</script>

<style scoped lang="scss">
.group {
  display: flex;
  margin-bottom: 8px;
  .action {
    margin-left: 16px;
  }
}
</style>
